.plan-card-widget-container {
    width: 100%;
    height: 596px;
    border-radius: 20px;
    border: 1px rgba(217, 217, 217, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.plan-card-widget-image-container {
    width: 100%;
    height: 474px;
    border-radius: 20px 20px 0 0;
}

.plan-card-widget-bottom-container {
    width: 100%;
    height: calc(100% - 474px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan-card-widget-bottom-internal-container {
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: space-between;
}

.plan-card-widget-text-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}