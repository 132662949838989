.survey-b-new-answers-container {
    width: 100%;
    height: 60%;
    overflow-y: auto;
    display: flex;
    align-items: start;
    justify-content: center;
}

.survey-b-new-break-page-container {
    width: 100%;
    height: 70%;
    overflow-y: auto;
    display: flex;
    align-items: start;
    justify-content: center;
}

.survey-b-new-answers-internal-container {
    width: 1500px;
    height: fit-content;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.survey-b-new-answers-internal-container-input {
    width: 1500px;
    height: fit-content;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.survey-b-new-break-page-internal-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.survey-b-new-break-page-internal-element {
    width: 400px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.survey-b-break-page-section-number {
    font-weight: 700;
    font-size: 15px;
    line-height: 35px;
    color: #0068FF;
}

.survey-b-break-page-section-name {
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
}

.survey-b-new-buttons-row {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.survey-b-new-internal-buttons-row {
    width: 70%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.survey-b-new-back-button {
    width: 122px;
    height: 60px;
    border-radius: 5px;
    border: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.survey-b-new-back-button-arrow {
    line-height: 0;
    margin-right: 2px;
}

.survey-b-new-back-button-text {
    margin-left: 2px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

.survey-b-new-confirm-button {
    width: 250px;
    height: 60px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

@media (max-width: 480px) {
    .survey-b-new-answers-internal-container {
        width: 100%;
        margin-top: 0px;
    }

    .survey-b-new-answers-internal-container-input {
        width: 100%;
        margin-top: 0px;
    }

    .survey-b-new-buttons-row {
        width: calc(100% - 30px);
    }

    .survey-b-new-internal-buttons-row {
        width: 100%;
    }

    .survey-b-new-back-button {
        width: 100px;
    }

    .survey-b-new-confirm-button {
        width: 200px;
    }
}