.platform-payment-method-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.payment-method-my-payments-container {
    display: flex;
    flex-direction: column;
    width: 600px;
}

.payment-method-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment-method-2-cards-container {
    height: 220px;
}

.payment-method-3-cards-container {
    height: 340px;
}

.payment-method-4-cards-container {
    height: 460px;
}

.payment-method-5-cards-container {
    height: 580px;
}

.payment-method-update-default-dialog-container {
    background-color: white;
    width: 572px;
    border: 1px rgba(234, 234, 234,1 ) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-method-update-default-dialog-container-2-cards-height {
    height: 250px;
}

.payment-method-update-default-dialog-container-3-cards-height {
    height: 290px;
}

.payment-method-update-default-dialog-container-4-cards-height {
    height: 330px;
}

.payment-method-update-default-dialog-container-5-cards-height {
    height: 370px;
}

.payment-method-update-default-dialog-internal-container {
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment-method-update-default-dialog-radio-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment-method-update-default-dialog-radio-button-container-2-cards-height {
    height: 80px;
}

.payment-method-update-default-dialog-radio-button-container-3-cards-height {
    height: 120px;
}

.payment-method-update-default-dialog-radio-button-container-4-cards-height {
    height: 160px;
}

.payment-method-update-default-dialog-radio-button-container-5-cards-height {
    height: 180px;
}

.payment-method-update-default-dialog-confirm-button {
    height: 55px;
    width: 100%;
    border: none;
    text-transform: uppercase;
    color: white;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.payment-method-update-default-error-message {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
    margin-left: 5px;
}

.platform-payment-method-update-default-dialog-radio-container {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 1px black solid;
    cursor: pointer;
}

.platform-payment-method-update-default-dialog-radio-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: black;
}

@media (max-width: 770px) {
    .payment-method-my-payments-container {
        width: 99%;
    }
}

@media (max-width: 480px) {
    .platform-payment-method-full-container {
        align-self: center;
    }
}