.platform-exams-and-tests-collecting-request-pickup-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.exams-and-tests-request-pickup-title-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    width: 89%;
}

.exams-and-tests-reques-pickup-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;
    width: 80%;
}

@media (max-width: 480px) {
    .platform-exams-and-tests-collecting-request-pickup-full-container {
        align-self: center;
    }

    .exams-and-tests-request-pickup-title-button-row {
        width: 100%;
    }

    .exams-and-tests-reques-pickup-buttons-container {
        flex-direction: column-reverse;
        justify-content: space-evenly;
        width: 100%;
        height: 130px;
    }
}