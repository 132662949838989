.next-appointment-widget-container {
    width: 100%;
    height: 214px;
    border-radius: 20px;
    border: 2px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.next-appointment-widget-container-urgent {
    width: 100%;
    height: 214px;
    border-radius: 20px;
    border: 2px rgba(0, 104, 255, 1) solid;
    background-color: rgba(239, 246, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.next-appointment-widget-upper-element-container {
    height: 70px;
}

.next-appointment-widget-internal-container {
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.next-appointment-widget-upper-element-heading {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
}

.next-appointment-widget-upper-element-date {
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
}

.next-appointment-widget-bottom-element-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.next-appointment-widget-bottom-element-container-mobile-only {
    display: none;
}

.next-appointment-widget-bottom-left-element {
    display: flex;
    flex-direction: column;
}

.next-appointment-widget-bottom-element-expert-position {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.next-appointment-widget-bottom-element-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.next-appointment-widget-bottom-element-expert-role {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 104, 255, 1);
}

.next-appointment-widget-bottom-element-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 430px;
}

.next-appointment-widget-bottom-element-edit-button {
    width: 167px;
    height: 55px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.next-appointment-widget-bottom-element-connect-button {
    width: 250px;
    height: 55px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.next-appointment-widget-red-text {
    color: rgba(255, 58, 55, 1);
}

.next-appointment-widget-blue-text {
    color: rgba(0, 104, 255, 1);
}

.next-appointment-widget-regular-font {
    font-weight: 400;
}

@media (max-width: 480px) {
    .next-appointment-widget-container {
        width: 99%;
        height: 326px;
    }

    .next-appointment-widget-container-urgent {
        width: 99%;
        height: 326px;
    }

    .next-appointment-widget-internal-container {
        width: 90%;
        height: 90%;
    }

    .desktop-only {
        display: none;
    }

    .next-appointment-widget-bottom-element-container-mobile-only {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .next-appointment-widget-bottom-element-buttons-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 120px;
    }

    .next-appointment-widget-bottom-element-edit-button-mobile {
        height: 55px;
        border: none;
        border-radius: 5px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
    }

    .next-appointment-widget-bottom-element-connect-button-mobile {
        height: 55px;
        border: none;
        border-radius: 5px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
    }
}