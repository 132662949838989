.product-choice-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80%;
    width: 288px;
}

.product-choice-success-copy {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
}