.platform-payment-method-add-new-method-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.platform-payment-method-add-new-method-radio-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-payment-method-add-new-method-card-chosen-height {
    height: 400px;
}

.platform-payment-method-add-new-method-paypal-chosen-height {
    height: 494px;
}

.platform-payment-method-add-new-method-standard-height {
    height: 200px;
}

.platform-payment-method-add-new-method-radio-container {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 1px black solid;
    cursor: pointer;
}

.platform-payment-method-add-new-method-radio-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: black;
}

.platform-payment-method-add-new-method-info-container {
    width: 632px;
    border-radius: 5px;
    border: 1px black solid;
    background-color: rgba(234, 234, 234, 1);
}

.platform-payment-method-add-new-method-button {
    width: 632px;
    height: 55px;
    text-transform: uppercase;
    border-radius: 5px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.platform-payment-method-add-new-method-credit-card-form {
    width: 632px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-payment-method-add-new-method-paypal-chosen-container {
    width: 632px;
    height: calc(842px /3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-payment-method-add-new-method-paypal-small-view {
    width: 600px;
    height: 150px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
}

.platform-payment-method-add-new-method-paypal-second-small-view {
    width: 600px;
    height: 100px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
}

.platform-payment-method-add-new-method-credit-card-form-single-line {
    height: 66px;
}

.platform-payment-method-add-new-method-credit-card-second-page {
    background-color: rgba(234, 234, 234, 1);
    width: 632px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.platform-payment-method-add-new-method-credit-card-second-page-form {
    width: 632px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-payment-method-add-new-method-input-field {
    line-height: 5em;
    border: none;
    width: 99%;
    height: 30px;
}

.platform-payment-method-add-new-method-input-field:focus {
    outline: none;
}

.platform-payment-method-add-new-method-input-field::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    color: rgba(95, 95, 95, 1);
}

.height-48 {
    height: 48px;
}

.width-408 {
    width: 408px;
}

.width-198 {
    width: 198px;
}

@media (max-width: 480px) {
    .platform-payment-method-add-new-method-full-container {
        align-self: center;
    }
}