.survey-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25%;
    width: 100%;
    border-bottom: 1px black solid;
}

.survey-bar-logo-container {
    display: flex;
    width: 70%;
    justify-content: space-between;
    height: 20%;
    align-items: center;
}

.survey-question-empty-space {
    width: 100%;
    height: 30%;
}

.survey-empty-bar {
    width: 80%;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(214, 227, 247, 1);
}

.survey-filled-bar {
    height: 4px;
    border-radius: 2px;
    background-color: rgba(0, 104, 255, 1);
}

.survey-question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: calc(100% - (30% + 45px));
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
}

.survey-question-number {
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
}

.survey-question {
    font-weight: 400;
    font-size: 35px;
    line-height: 41px;
}

.survey-question-title-two {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

@media (max-width: 480px) {
    .survey-question-empty-space {
        height: 10%;
    }

    .survey-question-container {
        height: calc(100% - (10% + 45px));
        justify-content: space-evenly;
        width: calc(100% - 30px);
    }

    .survey-question {
        font-size: 25px;
        line-height: 28px;
    }

    .survey-question-title-two {
        font-size: 18px;
        line-height: 20px;
    }

    .survey-bar-logo-container {
        width: calc(100% - 30px);
    }
}