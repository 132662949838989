.platform-my-purchases-redeem-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.my-purchases-redeem-copy-container {
    margin-top: 0%;
}

.my-purchases-redeem-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    width: 400px;
}

.my-purchases-redeem-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.my-purchases-redeem-input-field:focus {
    outline: none;
}

@media (max-width: 480px) {
    .platform-my-purchases-redeem-full-container {
        align-self: center;
    }

    .my-purchases-redeem-copy-container {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .my-purchases-redeem-form-container {
        width: 100%;
    }
}