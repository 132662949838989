.create-account-buttons-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    width: 90%;
}

.create-account-checkbox-container {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    border: 1px black solid;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.create-account-checkbox-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: black;
}