.mood-dialog-container {
    position: absolute;
    width: 758px;
    height: 522px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mood-dialog-internal-container {
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mood-dialog-heading {
    margin-left: 20px;
    font-weight: 700;
    font-size: 25px;
    line-height: 50px;
}

.mood-dialog-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
}

.mood-dialog-textarea {
    resize: none;
    width: 100%;
    height: 169px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
}

.mood-dialog-buttons-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mood-dialog-dont-send-info-button {
    width: 248px;
    height: 55px;
    border-radius: 5px;
    background-color: white;
    border: 1px black solid;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.mood-dialog-send-info-button {
    width: 347px;
    height: 55px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.mood-dialog-input-field {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 20px;
    border: none;
    background-color: transparent;
    width: 100%;
  }
  
  .mood-dialog-input-field:focus {
    outline: none;
  }
  
  .mood-dialog-input-field::placeholder {
    color: rgba(95, 95, 95, 1);
  }

  @media (max-width: 480px) {
    .mood-dialog-container {
        width: 90%;
    }

    .mood-dialog-heading {
       line-height: 25px; 
       font-size: 20px;
    }

    .mood-dialog-dont-send-info-button {
        width: 130px;
    }

    .mood-dialog-send-info-button {
        width: 170px;
    }
  }