.platform-exams-and-tests-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.exams-and-tests-cards-external-container {
    display: flex;
    align-items: start;
    width: 100%;
    height: 430px;
}

.exams-and-tests-cards-final-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 95%;
}

.exams-and-tests-drop-box-external-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 195px;
}

.exams-and-tests-drop-box-internal-container {
    display: flex;
    width: 100%;
    height: 90%;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #FFF5F8;
}

.exams-and-tests-drop-box-info-alert-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px 0px 0px 10px;
    width: 50%;
}

.exams-and-tests-drop-box-button-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    border-radius: 0px 10px 10px 0px;
    width: 50%;
    background-image: url("../../../../../../public/images/examsAndTests/drop-area.svg");
  }

  .exams-and-tests-documents-and-files-external-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25%;
  }

  .exams-and-tests-documents-and-files-internal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 90%;
  }

  .exams-and-tests-documents-and-files-card-row {
    display: flex;
    height: 70%;
  }

@media (max-width: 480px) {
    .platform-exams-and-tests-full-container {
        align-self: center;
    }

    .exams-and-tests-cards-external-container {
        height: max-content;
    }

    .exams-and-tests-cards-final-container {
        flex-direction: column;
    }

    .exams-and-tests-drop-box-external-container {
        height: max-content;
        margin-bottom: 20px;
    }

    .exams-and-tests-drop-box-internal-container {
        flex-direction: column;
    }

    .exams-and-tests-drop-box-info-alert-container {
        width: 100%;
        height: 162px;
        border-radius: 10px 10px 0px 0px;
    }

    .exams-and-tests-drop-box-button-container {
        width: 100%;
        height: 162px;
        border-radius: 0px 0px 10px 10px;
      }

      .exams-and-tests-documents-and-files-external-container {
        height: max-content;
      }

      .exams-and-tests-documents-and-files-card-row {
        flex-direction: column;
      }
}