.subscription-item-card-external-container {
    width: 600px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px black solid;
}

.subscription-item-card-interal-container {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subscription-item-card-left-element-container {
    display: flex;
}

.subscription-item-card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-left: 20px;
}

.subscription-item-card-date {
    width: 600px;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    justify-content: end;
}

.subscription-item-card-subtitle {
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
}