.my-purchases-handle-sub-dialog-container {
    position: absolute;
    width: 572px;
    height: 350px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-purchases-handle-sub-dialog-internal-container {
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.my-purchases-handle-sub-dialog-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
}

.my-purchases-handle-sub-dialog-buttons-container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.my-purchases-handle-sub-dialog-update-payment-method-button {
    width: 100%;
    height: 55px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    color: white;
    border: none;
}

.my-purchases-handle-sub-dialog-cancel-sub-button {
    width: 100%;
    height: 55px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    background-color: white;
    border: 2px rgba(255, 58, 55, 1) solid;
    color: rgba(255, 58, 55, 1);
}

.my-purchases-handle-sub-dialog-close-dialog-button {
    width: 100%;
    height: 55px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    background-color: white;
    border: 2px black solid;
}