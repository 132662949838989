.reward-page-bundle-product-container {
    display: flex;
    width: 1200px;
    height: 532px;
    border-radius: 20px;
    border: 1px black solid;
    align-items: center;
}

.reward-page-bundle-product-offers-container {
    margin-left: 20px;
    width: 491px;
    height: 474px;
    border-radius: 10px;
    background-color: rgba(244, 244, 244, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reward-page-bundle-product-expert-area-container {
    height: 100%;
    width: calc(100% - 474px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.reward-page-bundle-product-expert-area-internal-container {
    height: 474px;
    width: 621px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reward-page-bundle-product-goal-external-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 118px;
}

.reward-page-bundle-product-goal-internal-container {
    display: flex;
    align-items: center;
    width: 621px;
    height: 78px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.reward-page-bundle-product-price-purchase-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 85px;
}