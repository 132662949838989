.book-consultancy-widget-container {
    width: 100%;
    height: 230px;
    border-radius: 20px;
    display: flex;
    border: 2px rgba(0, 104, 255, 1) solid;
    background-color: rgba(239, 246, 255, 1);
}

.book-consultancy-widget-left-element-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.book-consultancy-widget-left-element-internal-container {
    width: 90%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.book-consultancy-widget-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.book-consultancy-widget-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.book-consultancy-widget-answer-button-desktop {
    width: 200px;
    height: 55px;
    color: white;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.book-consultancy-widget-answer-button-mobile {
    width: 200px;
    height: 55px;
    color: white;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: none;
}

.book-consultancy-widget-right-element-container {
    width: 50%;
    height: 100%;
    border-radius: 0px 20px 20px 0px;
    background-image: url("../../../../../../public/images/widgets/book-apointment-widget.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-consultancy-widget-right-element-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media (max-width: 480px) {
    .book-consultancy-widget-container {
        width: 99%;
        height: 500px;
        flex-direction: column;
    }

    .book-consultancy-widget-left-element-container {
        width: 100%;
        height: 50%;
    }

    .book-consultancy-widget-right-element-container {
        width: 100%;
        height: 50%;
        border-radius: 0px 0px 20px 20px;
    }

    .book-consultancy-widget-answer-button-desktop {
        display: none;
    }

    .book-consultancy-widget-answer-button-mobile {
        display: block;
    }
}