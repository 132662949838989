.drop-area-container-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32%;
    aspect-ratio: 1 / 1.5;
    border: 1px black dashed;
    border-radius: 10px;
    margin-bottom: 20px;
}

.drop-area-internal-container-desktop {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-area-link-container {
    width: 60%;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: rgba(59, 130, 247, 1);
    cursor: pointer;
    text-decoration-line: underline;
}

@media (max-width: 480px) {
    .drop-area-container-desktop {
        display: none;
    }
}