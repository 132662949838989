.your-feelings-widget-container {
    width: 100%;
    height: 223px;
    border-radius: 20px;
    border: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.your-feelings-widget-internal-container {
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.your-feelings-widget-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.your-feelings-widget-choice-row {
    display: flex;
    justify-content: space-between;
}

.your-feelings-widget-choice-card-container {
    width: 49%;
    height: 115px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
}

.your-feelings-widget-choice-card-checked-container {
    width: 49%;
    height: 115px;
    border-radius: 10px;
    background-color: rgba(40, 231, 197, 0.2);
    display: flex;
    align-items: center;
}

.your-feelings-widget-choice-card-copy {
    margin-left: 20px;
    font-weight: 700;
    font-size: 25px;
    line-height: 50px;
}

@media (max-width: 480px) {
    .your-feelings-widget-container {
        width: 99%;
        height: 350px;
    }

    .your-feelings-widget-internal-container {
        height: 90%;
    }

    .your-feelings-widget-choice-row {
        flex-direction: column;
        justify-content: space-between;
        height: 250px;
    }

    .your-feelings-widget-choice-card-container {
        width: 100%;
    }

    .your-feelings-widget-choice-card-checked-container {
        width: 100%;
    }
}