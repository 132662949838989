.reward-page-single-consultancy-container {
    width: 686px;
    height: 788px;
    border-radius: 20px;
    border: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reward-page-single-consultancy-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reward-page-single-consultancy-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
}

.reward-page-single-consultancy-offers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 234px;
}

.reward-page-single-consultancy-button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}