.checkout-form-success-container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkout-form-success-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
}

.checkout-form-success-copy {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}