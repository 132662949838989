.survey-b-home-external-container {
    width: 492px;
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.survey-b-home-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.survey-b-home-heading {
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
}

.survey-b-home-copy {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    width: 90%;
}

.survey-b-home-confirm-button {
    width: 328px;
    height: 60px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}