.update-tracking-widget-full-container {
    width: 900px;
    height: 62px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.update-tracking-widget-left-element-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.update-tracking-widget-left-element-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.update-tracking-widget-left-element-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}

.update-tracking-widget-button {
    width: 250px;
    height: 55px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    text-transform: uppercase;
}