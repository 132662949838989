.reward-page-single-test-container {
    width: 489px;
    height: 788px;
    border-radius: 20px;
    border: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reward-page-single-test-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reward-page-single-test-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
}

.reward-page-single-test-image {
    width: 418px;
    height: 415px;
    border-radius: 10px;
    background-color: rgba(247, 247, 247, 1);
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.reward-page-single-test-offers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
}

.reward-page-single-test-button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}