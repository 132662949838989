.my-answers-single-answer-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    border: 1px black solid;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.my-answers-single-answer-card-internal-container {
    width: 95%;
    height: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-answers-single-answer-card-left-element-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.my-answers-single-answer-card-question {
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
}

.my-answers-single-answer-card-answer {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.my-answers-single-answer-edit-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

@media (max-width: 480px) {
    .my-answers-single-answer-card-container {
        width: 99%;
    }
}