.buttons_row {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.input_field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.input_field:focus {
    outline: none;
}

input[type=password] {
    letter-spacing: 1px;
}

input::placeholder {
    color: rgba(95, 95, 95, 1);
}

input[type=radio] {
    /* width: 26px;
    height: 26px;
    accent-color: black; */
    opacity: 0;
   position: absolute;
   pointer-events: none;
}

input[type=checkbox] {
   opacity: 0;
   position: absolute;
   pointer-events: none;
}

/* input[type=password]:disabled::placeholder {
    color: rgba(95, 95, 95, 0.2);
} */

input:disabled::placeholder {
    color: rgba(95, 95, 95, 0.2);
}

input:disabled {
    background-color: transparent;
}

.login_not_able_to_log {
    margin-top: 10px;
    text-transform: uppercase;
    width: 328px;
    height: 45px;
    text-align: center;
    font-weight: 700;
}

.access_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.space_between {
    justify-content: space-between;
}

.drop_shadow {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.checkbox_container {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    border: 1px black solid;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.checkbox_internal_element {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: black;
}

.radio_container {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 1px black solid;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.radio_internal_element {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: black;
}