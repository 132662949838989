.pharmacies-register-swab-code-container {
    width: 70%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pharmacies-register-upper-element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
}

.pharmacies-register-swab-code-example-numbers-and-letters {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
    width: 600px;
    background-color: rgba(217, 217, 217, 1);
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
}

.pharmacies-register-swab-code-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 480px;
}

.pharmacies-register-swab-code-input-container {
    border-bottom: 1px solid black;
    width: 100%;
}

.pharmacies-register-swab-code-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.pharmacies-register-swab-code-input-field:focus {
    outline: none;
}

.pharmacies-register-swab-code-confirm-button {
    height: 55px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
    width: 100%;
    border: none;
    color: white;
    margin-top: 20px;
    text-align: center;
  }

@media (max-width: 600px) {
    .pharmacies-register-swab-code-example-numbers-and-letters {
        width: 480px;
    }
}

@media (max-width: 480px) {
    .pharmacies-register-swab-code-container {
        width: 100%;
    }

    .pharmacies-register-upper-element-container {
        width: 90%;
    }

    .pharmacies-register-swab-code-example-numbers-and-letters {
        width: 90%;
    }

    .pharmacies-register-swab-code-form-container {
        width: 90%;
    }
}