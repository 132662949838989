.next-apointment-widget-container {
    width: 100%;
    height: 230px;
    border-radius: 20px;
    display: flex;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    align-items: center;
    justify-content: center;
}

.next-apointment-widget-container-priority {
    width: 100%;
    height: 230px;
    border-radius: 20px;
    display: flex;
    border: 2px rgba(0, 104, 255, 1) solid;
    background-color: rgba(239, 246, 255, 1);
    align-items: center;
    justify-content: center;
}

.next-apointment-widget-internal-container {
    width: 97%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.next-apointment-widget-heading {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
}

.next-apointment-widget-date {
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
}

.next-apointment-widget-expert-position {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.next-apointment-widget-expert-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.next-apointment-widget-expert-role {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 104, 255, 1)
}

.next-apointment-widget-buttons-row {
    display: flex;
    justify-content: space-between;
    width: 430px
}

.next-apointment-widget-edit-button {
    width: 167px;
    height: 55px;
    border-radius: 5px;
    border: none;
    background-color: rgba(130, 129, 129, 1);
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

.next-apointment-widget-edit-button:disabled {
    background-color: rgba(130, 129, 129, 0.5);
}

.next-apointment-widget-connect-button {
    width: 251px;
    height: 55px;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    cursor: pointer;
}

.mobile {
    display: none;
}

.mobile-flex {
    display: none;
}

.next-apointment-widget-buttons-group-mobile {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.next-apointment-widget-button-mobile {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

@media (max-width: 480px) {
    .next-apointment-widget-container {
        width: 99%;
        height: 326px;
    }

    .next-apointment-widget-container-priority {
        width: 99%;
        height: 326px;
    }

    .next-apointment-widget-internal-container {
        width: 90%;
        height: 90%;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .mobile-flex {
        display: flex;
    }
}