.expert-comment-widget-container {
    width: 49%;
    height: 330px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert-comment-widget-internal-container {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.expert-comment-widget-upper-element {
    width: 100%;
    height: 60px;
}

.expert-comment-widget-content-container {
    width: 100%;
    height: calc(100% - 60px);
}

.expert-comment-widget-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.expert-comment-widget-author {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: rgba(0, 104, 255, 1);
}

.expert-comment-widget-comment {
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
}

@media (max-width: 480px) {
    .expert-comment-widget-container {
        width: 99%;
        margin-bottom: 20px;
    }
}