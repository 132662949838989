.quiz-q-new-reward-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 450px;
    justify-content: space-between;
}

.quiz-q-new-reward-page-heading {
    font-weight: 700;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
}
.quiz-q-new-reward-page-copy {
    font-weight: 400;
    font-size: 25px;
    line-height: 45px;
    text-align: center;
}

.quiz-q-new-reward-page-button {
    width: 364px;
    height: 60px;
    border-radius: 5px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
}

.quiz-q-new-reward-page-text-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
}

@media (max-width: 480px) {
    .quiz-q-new-reward-page-heading {
        font-size: 25px;
        line-height: 30px;
        width: 90%;
    }

    .quiz-q-new-reward-page-text-box {
        width: 100%;
    }

    .quiz-q-new-reward-page-copy {
        font-size: 20px;
        line-height: 25px;
    }
}