.swab-path-widget-container {
    width: 100%;
    height: 252px;
    border-radius: 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px rgba(234, 234, 234, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swab-path-widget-internal-container {
    width: 95%;
    height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swab-path-widget-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.swab-path-widget-path-container {
    width: 100%;
    height: calc(210px - 118px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.swab-path-widget-path-internal-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 100%;
    position: relative;
}

.swab-path-widget-single-path-element {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swab-path-widget-path-states {
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
}

.swab-path-widget-blue {
    color: rgba(0, 104, 255, 1);
}

.swab-path-widget-title-two {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
}

.swab-path-widget-progression-bar-empty {
    width: 95%;
    height: 4px;
    position: absolute;
    background-color: rgba(214, 227, 247, 1);
    top: calc(15% + 9px);
    left: 2.5%
}

.swab-path-widget-progression-bar-filled {
    background-color: rgba(0, 104, 255, 1);
    height: 4px;
}

.swab-path-widget-empty-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px rgba(214, 227, 247, 1) solid;
    background-color: white;
    align-self: center;
    z-index: 1;
}

.swab-path-widget-empty-dot-blue {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px rgba(0, 104, 255, 1) solid;
    background-color: white;
    align-self: center;
    z-index: 1;
}

.swab-path-widget-filled-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px rgba(0, 104, 255, 1) solid;
    background-color: rgba(0, 104, 255, 1);
    align-self: center;
    z-index: 1;
}

@media (max-width: 480px) {
    .swab-path-widget-container {
        width: 99%;
        height: 300px;
        overflow-x: auto;
    }

    .swab-path-widget-internal-container {
        height: 260px;
    }

    .swab-path-widget-path-container {
        width: 1000px;
    }

    .swab-path-widget-path-internal-container {
        width: 100%;
    }

    .swab-path-widget-progression-bar-empty {
        width: 100%
    }
}