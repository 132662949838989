.menu-view {
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    display: flex;
    flex-direction: row;
}

.menu-popup {
    background-color: rgba(220, 234, 255, 1);
    width: 400px;
    height: 100%;
    align-self: flex-start;
    filter: opacity(100%);
    z-index: 1;
}

.menu-popup-x-button {
    margin-right: 15%;
    cursor: pointer;
    line-height: 0px;
    display: none;
}

.menu-popup-blank-screen {
    background-color: transparent;
    width: calc(100% - 400px);
    height: 100%;
    cursor: pointer;
}

.menu-popup-title-text {
    color: rgba(0, 104, 255, 1);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
}

@media (max-width: 480px) {
    .menu-popup {
        width: 100%;
    }

    .menu-popup-blank-screen {
        width: 0%;
    }

    .menu-popup-x-button {
        display: block;
    }
}