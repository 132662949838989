/* reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: black;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* fine reset */

/* html, body, .root, .App {
  height: 100%;
} */

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/images/background-logo.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  /* font-family: 'Source Sans 3', sans-serif; */
  font-family: 'Roboto', sans-serif;
  /* letter-spacing: 0.5px; */
}

.Quiz {
  background-image: none;
}

.Test {
  background-color: lightgray;
}

.Loading {
  background-color: rgba(59, 130, 247, 0.5);
}

@media (max-width: 480px) {
  .App {
    background-image: none;
    height: 100dvh;
  }
}

.quiz_width {
  width: 100%;
}

@media (min-width: 1800px) {
  .quiz_width {
    width: 70%;
  }
}

/* Remove in the future */
.to_login {
  margin: 100px 0px;
  width: 400px;
  height: 150px;
  cursor: pointer;
}

/* Remove in the future */

.horizontal_flex_group {
  display: flex;
}

.vertical_flex_group {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.br2 {
  border-radius: 2px;
}

.br5 {
  border-radius: 5px;
}

.br9 {
  border-radius: 9px;
}

.br10 {
  border-radius: 10px;
}

.br10-left {
  border-radius: 10px 0 0 10px;
}

.br10-right {
  border-radius: 0 10px 10px 0;
}

.br10-top {
  border-radius: 10px 10px 0 0;
}

.br10-bottom {
  border-radius: 0 0 10px 10px;
}

.br20 {
  border-radius: 20px;
}

.br20-right {
  border-radius: 0 20px 20px 0;
}

.height_2em {
  height: 2em;
}

.height_3_percent {
  height: 3%
}

.height_5_percent {
  height: 5%;
}

.height_7_percent {
  height: 7%;
}

.height_10_percent {
  height: 10%;
}

.height_13_percent {
  height: 13%;
}

.height_15_percent {
  height: 15%;
}

.height_20_percent {
  height: 20%;
}

.height_25_percent {
  height: 25%;
}

.height_30_percent {
  height: 30%;
}

.height_33_percent {
  height: calc(100% /3);
}

.height_35_percent {
  height: 35%;
}

.height_40_percent {
  height: 40%;
}

.height_45_percent {
  height: 45%;
}

.height_50_percent {
  height: 50%;
}

.height_55_percent {
  height: 55%;
}

.height_60_percent {
  height: 60%;
}

.height_70_percent {
  height: 70%;
}

.height_75_percent {
  height: 75%;
}

.height_77_percent {
  height: 77%;
}

.height_80_percent {
  height: 80%;
}

.height_85_percent {
  height: 85%;
}

.height_90_percent {
  height: 90%;
}

.height_95_percent {
  height: 95%;
}

.height_93_percent {
  height: 93%;
}

.height_100_percent {
  height: 100%;
}

.height_1 {
  height: 1px;
}

.height_3 {
  height: 3px;
}

.height_4 {
  height: 4px;
}

.height_5 {
  height: 5px;
}

.height_10 {
  height: 10px;
}

.height_20 {
  height: 20px;
}

.height_35 {
  height: 35px;
}

.height_45 {
  height: 45px;
}

.height_48 {
  height: 48px;
}

.height_55 {
  height: 55px;
}

.height_90 {
  height: 90px;
}

.height_100 {
  height: 100px;
}

.height_212 {
  height: 212px;
}

.height_237 {
  height: 237px;
}

.height_256 {
  height: 256px;
}

.height_450 {
  height: 450px;
}

.height_500 {
  height: 500px;
}

.height_750 {
  height: 750px;
}

.width_0 {
  width: 0;
}

.width_2_percent {
  width: 2%;
}

.width_5_percent {
  width: 5%;
}

.width_6_percent {
  width: 6%;
}

.width_7_percent {
  width: 7%;
}

.width_8_percent {
  width: 8%;
}

.width_10_percent {
  width: 10%;
}

.width_11_percent {
  width: 11%;
}

.width_12_percent {
  width: 12%;
}

.width_13_percent {
  width: 13%;
}

.width_14_percent {
  width: 14%;
}

.width_15_percent {
  width: 15%;
}

.width_16_percent {
  width: 16%;
}

.width_17_percent {
  width: 17%;
}

.width_18_percent {
  width: 18%;
}

.width_20_percent {
  width: 20%;
}

.width_21_percent {
  width: 21%;
}

.width_22_percent {
  width: 22%;
}

.width_24_percent {
  width: 24%;
}

.width_25_percent {
  width: 25%;
}

.width_26_percent {
  width: 26%;
}

.width_28_percent {
  width: 28%;
}

.width_29_percent {
  width: 29%;
}

.width_30_percent {
  width: 30%;
}

.width_31_percent {
  width: 31%;
}

.width_32_percent {
  width: 32%;
}

.width_33_percent {
  width: 33%;
}

.width_35_percent {
  width: 35%;
}

.width_37_percent {
  width: 37%;
}

.width_38_percent {
  width: 38%;
}

.width_39_percent {
  width: 39%;
}

.width_40_percent {
  width: 40%;
}

.width_41_percent {
  width: 41%;
}

.width_42_percent {
  width: 42%;
}

.width_43_percent {
  width: 43%;
}

.width_44_percent {
  width: 44%;
}

.width_45_percent {
  width: 45%;
}

.width_46_percent {
  width: 46%;
}

.width_47_percent {
  width: 47%;
}

.width_48_percent {
  width: 48%;
}

.width_49_percent {
  width: 49%;
}

.width_50_percent {
  width: 50%;
}

.width_51_percent {
  width: 51%;
}

.width_52_percent {
  width: 52%;
}

.width_54_percent {
  width: 54%;
}

.width_56_percent {
  width: 56%;
}

.width_57_percent {
  width: 57%;
}

.width_58_percent {
  width: 58%;
}

.width_60_percent {
  width: 60%;
}

.width_61_percent {
  width: 61%;
}

.width_62_percent {
  width: 62%;
}

.width_63_percent {
  width: 63%;
}

.width_64_percent {
  width: 64%;
}

.width_67_percent {
  width: 67%;
}

.width_68_percent {
  width: 68%;
}

.width_70_percent {
  width: 70%;
}

.width_71_percent {
  width: 71%;
}

.width_72_percent {
  width: 72%;
}

.width_73_percent {
  width: 72%;
}

.width_75_percent {
  width: 75%;
}

.width_76_percent {
  width: 76%;
}

.width_77_percent {
  width: 77%;
}

.width_78_percent {
  width: 78%;
}

.width_79_percent {
  width: 79%;
}

.width_80_percent {
  width: 80%;
}

.width_82_percent {
  width: 82%;
}

.width_83_percent {
  width: 83%;
}

.width_84_percent {
  width: 84%;
}

.width_85_percent {
  width: 85%;
}

.width_86_percent {
  width: 86%;
}

.width_87_percent {
  width: 87%;
}

.width_88_percent {
  width: 88%;
}

.width_89_percent {
  width: 89%;
}

.width_90_percent {
  width: 90%;
}

.width_91_percent {
  width: 91%;
}

.width_92_percent {
  width: 92%;
}

.width_95_percent {
  width: 95%;
}

.width_96_percent {
  width: 96%;
}

.width_98_percent {
  width: 98%;
}

.width_100_percent {
  width: 100%;
}

.width_20 {
  width: 20px;
}

.width_24 {
  width: 24px;
}

.width_30 {
  width: 30px;
}

.width_40 {
  width: 40px;
}

.width_60 {
  width: 60px;
}

.width_109 {
  width: 109px;
}

.width_125 {
  width: 125px;
}

.width_129 {
  width: 129px;
}

.width_159 {
  width: 159px;
}

.width_145 {
  width: 145px;
}

.width_200 {
  width: 200px;
}

.width_250 {
  width: 250px;
}

.width_288 {
  width: 288px;
}

.width_328 {
  width: 328px;
}

.width_392 {
  width: 392px;
}

.width_396 {
  width: 396px;
}

.width_408 {
  width: 408px;
}

.width_428 {
  width: 428px;
}

.width_410 {
  width: 410px;
}

.width_450 {
  width: 450px;
}

.width_456 {
  width: 456px;
}

.width_492 {
  width: 492px;
}

.width_731 {
  width: 731px;
}

.width_1000 {
  width: 1000px;
}

/* Background colors */

.primary_action_color_background {
  background-color: rgba(59, 130, 247, 1);
}

.primary_action_color_background:hover {
  background-color: rgba(70, 140, 255, 1);
}

.primary_action_color_background:disabled {
  background-color: rgba(59, 130, 247, 0.5);
}

.primary_action_color_background:active {
  background-color: rgba(35, 107, 226, 1);
}

.secondary_action_color_background {
  background-color: rgba(40, 231, 197, 1);
}

.secondary_action_color_background:hover {
  background-color: rgba(53, 242, 208, 1);
}

.secondary_action_color_background:disabled {
  background-color: rgba(40, 231, 197, 0.5);
}

.secondary_action_color_background:active {
  background-color: rgba(23, 215, 181, 1);
}

.ternary_action_color_background {
  background-color: rgba(146, 146, 146, 1);
}

.ternary_action_color_background:hover {
  background-color: rgba(160, 160, 160, 1);
}

.ternary_action_color_background:disabled {
  background-color: rgba(146, 146, 146, 0.5);
}

.ternary_action_color_background:active {
  background-color: rgba(130, 129, 129, 1);
}

.alert_color_background {
  background-color: rgba(243, 212, 52, 1);
}

.error-color-background {
  background-color: rgba(234, 67, 53, 1);
}

.tnt-color-background {
  background-color: rgba(255, 102, 0, 1);
}

.tnt-color-background:hover {
  background-color: rgba(255, 127, 41, 1);
}

.tnt-color-background:disabled {
  background-color: rgba(255, 102, 0, 0.5);
}

.tnt-color-background:active {
  background-color: rgba(228, 93, 3, 1);
  ;
}

.green-label-background {
  background-color: rgba(104, 166, 129, 1);
}

.light-yellow-background {
  background-color: rgba(255, 235, 128, 0.8);
}

.yellow-alert-background {
  background-color: rgba(247, 235, 175, 1);
}

.light-gray-background {
  background-color: rgba(247, 247, 247, 1);
}

.gray-background {
  background-color: rgba(234, 234, 234, 1);
}

.dark-gray-background {
  background-color: rgba(217, 217, 217, 1);
}

.card_selected_background {
  background-color: rgba(40, 231, 197, 0.2);
}

.progress_bar_empty_background {
  background-color: rgba(214, 227, 247, 1);
}

.progress_bar_completed_background {
  background-color: rgba(0, 104, 255, 1);
}

.transparent_background {
  background-color: transparent;
}

.green_background {
  background-color: rgba(40, 231, 197, 1);
}

.green_background:hover {
  background-color: rgba(53, 242, 208, 1);
}

.green_background:disabled {
  background-color: rgba(40, 231, 197, 0.5);
}

.green_background:active {
  background-color: rgba(23, 215, 181, 1);
}

.blue_background {
  background-color: rgba(59, 130, 247, 1);
}

.blue_background:hover {
  background-color: rgba(70, 140, 255, 1);
}

.blue_background:disabled {
  background-color: rgba(59, 130, 247, 0.5);
}

.blue_background:active {
  background-color: rgba(35, 107, 226, 1);
}

.white_background {
  background-color: white;
}

.black_background {
  background-color: black;
}

.light_black_background {
  background-color: rgba(0, 0, 0, 0.2);
}

.swab_choice_background {
  background-color: white;
}

.swab_choice_background:hover {
  background-color: lightgray;
}

.swab_choice_background:active {
  background-color: #5f5f5f;
}

.resend_code_button_background {
  background-color: transparent;
}

.resend_code_button_background:hover {
  background-color: lightgray;
}

.resend_code_button_background:active {
  background-color: #5f5f5f;
}

/* Text colors */

.primary_action_color_text {
  color: rgba(59, 130, 247, 1);
}

.secondary_action_color_text {
  color: rgba(40, 231, 197, 1);
}

.ternary_action_color_text {
  color: rgba(146, 146, 146, 1);
}

.error_color_text {
  color: rgba(234, 67, 53, 1);
}

.alert_color_text {
  color: rgba(243, 212, 52, 1);
}

.white_text {
  color: white;
}

.blue_text {
  color: #3B82F7;
}

.error_text {
  color: #EA4335;
}

.gray_text {
  color: #5f5f5f;
}

.light_gray_text {
  color: lightgray;
}

.dark_gray_text {
  color: rgba(95, 95, 95, 1);
}

.black_text {
  color: black;
}

.light_black_text {
  color: rgba(0, 0, 0, 0.2);
}

/* Borders */

.no_border {
  border: none;
}

.black_border {
  border: 1px black solid;
}

.black-border-dashed {
  border: 1px black dashed;
}

.gray_border {
  border: 1px rgba(234, 234, 234, 1) solid;
}

.secondary_action_color_border {
  border: 1px rgba(40, 231, 197, 1) solid;
}

.error_color_border {
  border: 1px rgba(234, 67, 53, 1) solid;
}

.black_border_bottom {
  border-bottom: 1px solid black;
}

.gray_border_bottom {
  border-bottom: 1px solid rgba(95, 95, 95, 1);
}

.light_gray_border_bottom {
  border-bottom: 1px solid rgba(95, 95, 95, 0.2);
}

/* Decorations */

.underline_text {
  text-decoration-line: underline;
}

.no_underline_text {
  text-decoration-line: none;
}

.no_decoration_text {
  text-decoration: none;
}

.italic_text {
  font-style: italic;
}

/* Weights */

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

/* Sizes */

.s12 {
  font-size: 12px;
}

.s14 {
  font-size: 14px;
}

.s15 {
  font-size: 15px;
}

.s16 {
  font-size: 16px;
}

.s18 {
  font-size: 18px;
}

.s20 {
  font-size: 20px;
}

.s25 {
  font-size: 25px;
}

.s28 {
  font-size: 28px;
}

.s30 {
  font-size: 30px;
}

.s35 {
  font-size: 35px;
}

.s40 {
  font-size: 40px;
}

/* Text aligns */

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

/* Margins */

.margin_top_5 {
  margin-top: 5px;
}

.margin_top_10 {
  margin-top: 10px;
}

.margin_top_15 {
  margin-top: 15px;
}

.margin_top_20 {
  margin-top: 20px;
}

.margin_top_21 {
  margin-top: 21px;
}

.margin_top_22 {
  margin-top: 22px;
}

.margin_top_23 {
  margin-top: 23px;
}

.margin_top_25 {
  margin-top: 25px;
}

.margin_top_30 {
  margin-top: 30px;
}

.margin_top_40 {
  margin-top: 40px;
}

.margin_top_50 {
  margin-top: 50px;
}

.margin_top_60 {
  margin-top: 60px;
}

.margin_top_70 {
  margin-top: 70px;
}

.margin_top_80 {
  margin-top: 80px;
}

.margin_top_100 {
  margin-top: 100px;
}

.margin_top_150 {
  margin-top: 150px;
}

.margin_top_231 {
  margin-top: 231px;
}

.margin_top_250 {
  margin-top: 250px;
}

.margin_bottom_5 {
  margin-bottom: 5px;
}

.margin_bottom_10 {
  margin-bottom: 10px;
}

.margin_bottom_15 {
  margin-bottom: 15px;
}

.margin_bottom_20 {
  margin-bottom: 20px;
}

.margin_bottom_30 {
  margin-bottom: 30px;
}

.margin_bottom_40 {
  margin-bottom: 40px;
}

.margin_bottom_50 {
  margin-bottom: 50px;
}

.margin_bottom_60 {
  margin-bottom: 60px;
}

.margin_bottom_70 {
  margin-bottom: 70px;
}

.margin_bottom_80 {
  margin-bottom: 80px;
}

.margin_bottom_100 {
  margin-bottom: 100px;
}

.margin_bottom_150 {
  margin-bottom: 150px;
}

.margin_bottom_200 {
  margin-bottom: 200px;
}

.margin_bottom_250 {
  margin-bottom: 250px;
}

.margin_left_2 {
  margin-left: 2px;
}

.margin_left_3 {
  margin-left: 3px;
}

.margin_left_4 {
  margin-left: 4px;
}

.margin_left_5 {
  margin-left: 5px;
}

.margin_left_10 {
  margin-left: 10px;
}

.margin_left_15 {
  margin-left: 15px;
}

.margin_left_20 {
  margin-left: 20px;
}

.margin_left_30 {
  margin-left: 30px;
}

.margin_left_40 {
  margin-left: 40px;
}

.margin_left_80 {
  margin-left: 80px;
}

.margin_left_120 {
  margin-left: 120px;
}

.margin_right_2 {
  margin-right: 2px;
}

.margin_right_3 {
  margin-right: 3px;
}

.margin_right_4 {
  margin-right: 4px;
}

.margin_right_5 {
  margin-right: 5px;
}

.margin_right_10 {
  margin-right: 10px;
}

.margin_right_15 {
  margin-right: 15px;
}

.margin_right_20 {
  margin-right: 20px;
}

.margin_right_30 {
  margin-right: 30px;
}

.margin_right_40 {
  margin-right: 40px;
}

.margin_right_50 {
  margin-right: 50px;
}

.margin_right_60 {
  margin-right: 60px;
}

.margin_right_80 {
  margin-right: 80px;
}

.margin_right_120 {
  margin-right: 120px;
}

.margin_5 {
  margin: 5px;
}

.margin_10 {
  margin: 10px;
}

.margin_20 {
  margin: 20px;
}

.margin_30 {
  margin: 30px;
}

.margin_40 {
  margin: 40px;
}

.margin_50 {
  margin: 50px;
}

.margin_100 {
  margin: 100px;
}

.uppercase {
  text-transform: uppercase;
}

.roboto_text {
  font-family: 'Roboto', sans-serif;
}

/* Items alignemnts */

.align_items_start {
  align-items: start;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: end;
}

/* Line heights */

.lh0 {
  line-height: 0;
}

.lh17 {
  line-height: 17.09px;
}

.lh20 {
  line-height: 20px;
}

.lh22 {
  line-height: 22px;
}

.lh24 {
  line-height: 24px;
}

.lh26 {
  line-height: 26px;
}

.lh30 {
  line-height: 30px;
}

.lh35 {
  line-height: 35px;
}

.lh38 {
  line-height: 38px;
}

.lh40 {
  line-height: 40px;
}

.lh47 {
  line-height: 47px;
}

.lh60 {
  line-height: 60px;
}

.self_start {
  align-self: flex-start;
}

.self_end {
  align-self: flex-end;
}

.self_center {
  align-self: center;
}

.ls1 {
  letter-spacing: 1px;
}

.image {
  line-height: 0;
}

.invisible {
  visibility: hidden;
}

.error {
  color: #db4437;
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
  margin-top: 4px;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_space_around {
  justify-content: space-around;
}

.justify_content_space_evenly {
  justify-content: space-evenly;
}

.justify_content_space_between {
  justify-content: space-between;
}

.justify_content_flex_end {
  justify-content: flex-end;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading_spinner {
  width: 60px;
  height: 60px;
  border: 3px solid rgba(59, 130, 247, 1);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner_container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.input-answer-card-input-field {
  margin-left: 40px;
  font-size: 35px;
  border: none;
  background-color: transparent;
  width: 100%;
}

.input-answer-card-input-field:focus {
  outline: none;
}

.input-answer-card-input-field::placeholder {
  color: rgba(95, 95, 95, 1);
}

.answer_input_field {
  margin-left: 40px;
  font-size: 35px;
  border: none;
  background-color: transparent;
  width: 100%;
}

.answer_input_field:focus {
  outline: none;
}

.answer_input_field::placeholder {
  color: rgba(95, 95, 95, 1);
}

.answer_input_radio_field {
  font-size: 20px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  width: 100%;
}

.answer_input_radio_field:focus {
  outline: none;
}

.answer_input_radio_field::placeholder {
  color: rgba(95, 95, 95, 1);
}

.vertical-navbar {
  background-color: rgba(8, 20, 40, 1);
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0px;
}

.visible_30_percent {
  filter: opacity(30%);
}

.visible-0-percent {
  filter: opacity(0%);
}



.dashboard-content {
  align-self: end;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.platform-entire-screen-content {
  width: 93%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.menu-popup-element-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
}

.single-view-title-text {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
}

.close-button {
  align-self: flex-end;
}

.vertical-scroll-group {
  overflow-y: auto;
  height: 80%;
}

.vertical-scroll-group-87 {
  overflow-y: auto;
  height: 87%;
}

.vertical-scroll-group-90 {
  overflow-y: auto;
  height: 90%;
}

.vertical-scroll-group-100 {
  overflow-y: auto;
  height: 100%;
}

.vertical-scroll-group-max-content {
  overflow-y: auto;
  height: max-content;
}

.dashboard-element-1x1 {
  height: 15%;
  width: 16.5%;
}

.dashboard-element-100x1 {
  height: 15%;
  width: 100%;
}

.dashboard-element-100x2 {
  height: 30%;
  width: 100%;
}

.dashboard-element-66x2 {
  height: 30%;
  width: 66%;
}

.dashboard-element-66x4 {
  height: 60%;
  width: 66%;
}

.dashboard-element-66x5 {
  height: 70%;
  width: 66%;
}

.dashboard-element-33x2 {
  height: 30%;
  width: 33%;
  border-radius: 20px;
  border: 1px rgba(234, 234, 234, 1) solid;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.inactive {
  pointer-events: none;
}

.exams-and-test-view {
  align-self: end;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.specific-exam-page {
  align-self: end;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.dna-test-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dna-test-page-content {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.dna-test-page-tutorial {
  width: 89%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dna-test-page-swab-register {
  width: 89%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dna-test-page-packing {
  width: 89%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dna-test-page-request-pickup {
  width: 89%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.standard-scroll-group {
  overflow-y: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-center-40x40 {
  top: calc(50% - height/2);
  left: 50%;
  margin-left: -20%
}

.absolute-center-50x50 {
  top: calc(50% - height/2);
  left: 50%;
  margin-left: -25%
}

.absolute-center-40x70 {
  top: calc(50% - height/2);
  left: 50%;
  margin-left: -20%
}

.absolute-center-40x80 {
  top: calc(50% - height/2);
  left: 50%;
  margin-left: -20%
}

.absolute-center-40x10 {
  top: 50%;
  left: 50%;
  margin-top: -5%;
  margin-left: -20%
}

.absolute-center-50x30 {
  top: 50%;
  left: 50%;
  margin-top: -15%;
  margin-left: -25%
}

.margin-7-percent {
  margin: 7%;
}

.margin-top-1-percent {
  margin-top: 1%;
}

.margin-top-7-percent {
  margin-top: 7%;
}

.margin-right-2-percent {
  margin-right: 2%;
}

.margin-right-3-percent {
  margin-right: 3%;
}

.margin-right-4-percent {
  margin-right: 4%;
}

.margin-right-5-percent {
  margin-right: 5%;
}

.margin-left-2-percent {
  margin-left: 2%;
}

.margin-left-3-percent {
  margin-left: 3%;
}

.margin-left-4-percent {
  margin-left: 4%;
}

.margin-left-5-percent {
  margin-left: 5%;
}

.margin-top-3-percent {
  margin-top: 3%;
}

.margin-top-4-percent {
  margin-top: 4%;
}

.margin-top-5-percent {
  margin-top: 5%;
}

.margin-bottom-1-percent {
  margin-bottom: 1%;
}

.margin-bottom-2-percent {
  margin-bottom: 2%;
}

.margin-bottom-3-percent {
  margin-bottom: 3%;
}

.margin-bottom-4-percent {
  margin-bottom: 4%;
}

.margin-bottom-5-percent {
  margin-bottom: 5%;
}

.margin-left-7-percent {
  margin-left: 7%;
}

.margin-left-10-percent {
  margin-left: 10%;
}

.margin-left-15-percent {
  margin-left: 15%;
}

.margin-right-15-percent {
  margin-right: 15%;
}

.margin-right-7-percent {
  margin-right: 7%;
}

.margin-bottom-7-percent {
  margin-bottom: 7%;
}

.file-input-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-input-form-h1 {
  font-weight: 600;
  font-size: 1.3vw;
  line-height: 1.3em;
  text-align: center;
  width: 50%;
}

.file-input-form-h2 {
  font-weight: 600;
  font-size: 0.91vw;
  line-height: 26px;
  text-align: center;
}

.file-input-h0 {
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 40px;
}

.file-input-h1-2 {
  font-weight: 400;
  font-size: 1vw;
  line-height: 40px;
}

.fontsize-input-file {
  font-size: 0.9vw;
  font-weight: 700;
  line-height: 3vh;
  text-align: left;
}

.fontsize-input-file-small {
  font-size: 0.9vw;
  font-weight: 400;
  line-height: 3vh;
  text-align: left;
}

.sample-path-text {
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 40px;
}

.sample-path-text-two {
  font-weight: 700;
  font-size: 0.94595vw;
  line-height: 40px;
}

.sample-path-text-iter {
  font-weight: 700;
  font-size: 0.8277vw;
  line-height: 40px;
  align-self: center;
}

.sample-path-empty-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px rgba(214, 227, 247, 1) solid;
  background-color: white;
  align-self: center;
  z-index: 1;
}

.sample-path-empty-dot-blue {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px rgba(0, 104, 255, 1) solid;
  background-color: white;
  align-self: center;
  z-index: 1;
}

.blue-text-swab-path {
  color: rgba(0, 104, 255, 1);
}

.sample-path-filled-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px rgba(0, 104, 255, 1) solid;
  background-color: rgba(0, 104, 255, 1);
  align-self: center;
  z-index: 1;
}

.platform-exams-and-tests-collecting-full-container {
  align-self: end;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.platform-exams-and-tests-collecting-tutorial-full-container {
  align-self: end;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.platform-exams-and-tests-collecting-swab-register-full-container {
  align-self: end;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.center-div {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

iframe {
  border-radius: 10px 10px 0 0;
}

ul {
  list-style-type: disc;
}

/* Components used several times */

.small-button {
  width: 159px;
  height: 55px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
}

.medium-button {
  width: 250px;
  height: 55px;
  text-transform: uppercase;
  border-radius: 5px;
}

@media (max-width: 480px) {
  .medium-button {
    width: 159px;
  }
}

.choose-product-radio-container {
  width: 26px;
  height: 26px;
  border-radius: 50px;
  border: 1px black solid;
  cursor: pointer;
}

.choose-product-radio-internal-element {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: black;
}

.survey-answers-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 80%;
  overflow-y: auto;
  justify-content: center;
  align-content: flex-start;
}

.survey-buttons-row {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 3%;
}

@media (max-width: 480px) {
  .survey-buttons-row {
    width: 90%;
    align-self: center;
  }
}

.platform-full-page {
  display: flex;
  width: 100%;
  height: 100%;
}

@media (max-width: 480px) {
  .platform-full-page {
    width: 100%;
    height: 90%;
    overflow-y: auto;
  }

  .vertical-navbar {
    visibility: hidden;
  }
}

.horizontal-navbar {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(8, 20, 40, 1);
  position: absolute;
  bottom: 0px;
}

@media (max-width: 480px) {
  .horizontal-navbar {
    visibility: visible;
  }
}

.horizontal-navbar-logo-container {
  width: 25%;
  height: 100%;
}

@media (max-width: 480px) {
  .platform-exams-and-tests-collecting-full-container {
    align-self: center;
  }

  .platform-exams-and-tests-collecting-tutorial-full-container {
    align-self: center;
  }

  .platform-exams-and-tests-collecting-swab-register-full-container {
    align-self: center;
  }
}

.platform-final-element-container {
  width: 89%;
  height: 100%;
}

@media (max-width: 480px) {
  .platform-final-element-container {
    width: 100%;
  }
}

.exams-and-tests-documents-and-files-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  height: 100%;
  border: 1px black solid;
  margin-right: 10px;
  border-radius: 10px;
}

@media (max-width: 480px) {


  .exams-and-tests-documents-and-files-card {
    width: 99%;
    height: 149px;
    margin-bottom: 20px;
  }
}

.dna-test-collecting-copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  height: 10%;
}

@media (max-width: 480px) {
  .dna-test-collecting-copy {
    height: 15%;
  }
}

.delete-file-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  height: 30%;
}

@media (max-width: 480px) {
  .delete-file-dialog {
    width: 100%;
  }
}

.dna-test-collecting-elements-external-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  width: 100%;
}

.dna-test-collecting-elements-internal-container {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
}

@media (max-width: 480px) {
  .dna-test-collecting-elements-external-container {
    height: max-content;
    margin-top: 20px;
  }
}

.swab-path-container {
  display: flex;
  flex-direction: column;
  height: 30%;
  width: 100%;
  border-radius: 20px;
  border: 1px rgba(234, 234, 234, 1) solid;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.swab-path-container-bigger {
  display: flex;
  flex-direction: column;
  height: 45%;
  width: 100%;
  border-radius: 20px;
  border: 1px rgba(234, 234, 234, 1) solid;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.swab-path-bullet-points {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.swab-path-title-two {
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  margin-left: 40px;
  height: 35%;
}

.swab-path-bullet-points-mobile {
  height: 75%;
  display: none;
}

@media (max-width: 480px) {
  .swab-path-container {
    height: 260px;
    margin-bottom: 20px;
    width: 99%;
  }

  .swab-path-container-bigger {
    height: 350px;
    margin-bottom: 20px;
    width: 99%;
  }

  .swab-path-bullet-points {
    display: none;
  }

  .swab-path-title-two {
    display: none;
  }

  .swab-path-bullet-points-mobile {
    display: flex;
    flex-direction: column;
  }
}

.collecting-overview-container {
  display: flex;
  border: 1px black solid;
  border-radius: 20px;
}

.collecting-overview-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.collecting-overview-confirm-button {
  margin-top: 3%;
  cursor: pointer;
  width: 200px;
  height: 55px;
  border-radius: 5px;
  border: 1px black solid;
  font-size: 18px;
  text-transform: uppercase;
  background-color: white;
}

.collecting-overview-confirm-button-desktop-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.collecting-overview-confirm-button-mobile-container {
  margin-left: 7%;
  margin-bottom: 7%;
  display: none;
}

.collecting-overview-image {
  width: 50%;
  background-color: rgba(247, 247, 247, 1);
  border-radius: 0px 20px 20px 0px;
}

@media (max-width: 480px) {
  .collecting-overview-container {
    width: 99%;
    height: max-content;
    flex-direction: column;
  }

  .collecting-overview-description {
    width: 100%;
    height: max-content;
  }

  .collecting-overview-image {
    margin-top: 20px;
    width: 100%;
    height: 220px;
    border-radius: 0px;
  }

  .collecting-overview-confirm-button-mobile-container {
    display: inline;
  }

  .collecting-overview-confirm-button-desktop-container {
    display: none;
  }
}

.dna-test-tutorial-title-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  width: 89%;
}

@media (max-width: 480px) {
  .dna-test-tutorial-title-button-row {
    width: 100%;
  }
}

.dna-test-tutorial-section-1-copy-video-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80%;
  width: 80%;
}

.dna-test-tutorial-section-1-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  width: 80%;
}

.dna-test-tutorial-section-1-button {
  width: 40%;
  height: 55px;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
}

.dna-test-tutorial-section-2-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  width: 80%;
}

@media (max-width: 480px) {
  .dna-test-tutorial-section-1-copy-video-container {
    width: 100%;
    height: 70%;
  }

  .dna-test-tutorial-section-1-buttons-container {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    width: 100%;
    height: 30%;
  }

  .dna-test-tutorial-section-1-button {
    width: 100%;
  }

  .dna-test-tutorial-section-2-buttons-container {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: column-reverse;
    height: 140px;
  }
}

.mobile-scroll-100 {}

@media (max-width: 480px) {
  .mobile-scroll-100 {
    height: max-content;
    overflow-y: auto;
  }
}

.dna-test-register-swab-code-title-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  width: 89%;
}

.dna-test-register-swab-code-content-container {
  display: flex;
  flex-direction: column;
  height: 80%
}

.dna-test-register-swab-code-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 20%;
}

.dna-test-regster-swab-code-upper-elements-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20%;
}

.dna-test-register-swab-code-input-container {
  border-bottom: 1px solid black;
  width: 45%;
}

.dna-test-register-swab-code-example-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 60%;
  width: 12%;
}

.dna-test-register-swab-code-example-box-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
}

.dna-test-register-swab-code-example-numbers-and-letters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40%;
  width: 80%;
  background-color: rgba(217, 217, 217, 1);
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.swab-code-example-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  height: 30%;

}

@media (max-width: 480px) {
  .dna-test-register-swab-code-title-button-row {
    width: 100%;
  }

  .dna-test-register-swab-code-buttons-container {
    width: 100%;
    flex-direction: column-reverse;
    height: 130px;
  }

  .swab-code-example-dialog {
    width: 100%;
  }

  .dna-test-register-swab-code-content-container {}

  .dna-test-regster-swab-code-upper-elements-container {
    margin-top: 10px;
    height: 200px;
    justify-content: center;
  }

  .dna-test-register-swab-code-input-container {
    width: 100%;
    margin-bottom: 50px;
  }

  .dna-test-register-swab-code-example-numbers-and-letters {
    width: 100%;
    flex-wrap: wrap;
    height: 350px;
    align-items: safe;
  }

  .dna-test-register-swab-code-example-box-text {
    font-size: 10px;
  }

  .dna-test-register-swab-code-example-box {
    width: 100px;
    height: 40%;
  }
}

.dna-test-register-swab-code-register-button {
  width: 40%;
  height: 55px;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  color: white;
}

.dna-test-register-swab-code-back-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  width: 109px;
  height: 55px;
  border: 1px black solid;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 480px) {
  .dna-test-register-swab-code-register-button {
    width: 100%;
  }

  .dna-test-register-swab-code-back-button {
    width: 100%;
    justify-content: center;
  }
}

.dna-test-packing-element-container {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.dna-test-packing-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  width: 80%;
}

.dna-test-packing-confirm-button {
  width: 40%;
  height: 55px;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: white;
}

.dna-test-packing-back-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  width: 109px;
  height: 55px;
  border: 1px black solid;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 480px) {
  .dna-test-packing-element-container {
    margin-top: 20px;
    margin-bottom: 30px;
    height: max-content;
  }

  .dna-test-packing-buttons-container {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    width: 100%;
    height: 130px;
  }

  .dna-test-packing-confirm-button {
    width: 100%;
  }

  .dna-test-packing-back-button {
    width: 100%;
    justify-content: center;
  }
}

.dna-test-pickup-request-radio-choices-container {
  display: flex;
  align-items: center;
  width: 80%;
  height: 25%;
}

.dna-test-pickup-request-radio-choice-element {
  display: flex;
  align-items: center;
  width: 450px;
  height: 100px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 10px;
  line-height: 0px;
  margin-bottom: 3%;
}

.dna-test-picup-request-alert-box {
  display: flex;
  flex-direction: column;
  width: 80%;
  border-radius: 10px;
}

.dna-test-pickup-request-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  width: 80%;
}

.dna-test-pickup-request-confirm-button {
  width: 40%;
  height: 55px;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: white;
}

.dna-test-pickup-request-back-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  width: 109px;
  height: 55px;
  border: 1px black solid;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 480px) {
  .dna-test-pickup-request-radio-choices-container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    flex-direction: column;
  }

  .dna-test-pickup-request-radio-choice-element {
    width: 99%;
  }

  .dna-test-picup-request-alert-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .dna-test-pickup-request-buttons-container {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    width: 100%;
    height: 130px;
  }

  .dna-test-pickup-request-confirm-button {
    width: 100%;
  }

  .dna-test-pickup-request-back-button {
    width: 100%;
    justify-content: center;
  }
}

.experts-tag-container {
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(255, 232, 171, 1);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  width: fit-content;
}

.red-text {
  color: rgba(255, 58, 55, 1);
}

.input-answer-card-width {
  width: 30%;
}

@media (max-width: 480px) {
  .input-answer-card-width {
    width: 90%;
  }
}

.big-input-answer-card {
  display: flex;
  width: 70%;
  height: 30%;
  border-radius: 10px;
  background-color: white;
}

@media (max-width: 480px) {
  .big-input-answer-card {
    width: 90%;
  }
}

.big-input-field {
  margin-left: 3%;
  font-size: 35px;
  border: none;
  background-color: transparent;
  width: 100%;
}

.big-input-field:focus {
  outline: none;
}

.big-input-field::placeholder {
  color: rgba(95, 95, 95, 1);
}