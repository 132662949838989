.multiselect-answer-card-container {
    width: 450px;
    height: 100px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
}

.multiselect-answer-checked-card-container {
    width: 450px;
    height: 100px;
    border-radius: 10px;
    border: 1px transparent solid;
    box-shadow: 0px 1px 4px 0px transparent;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(40, 231, 197, 0.2);
    cursor: pointer;
}

.multiselect-answer-card-internal-container {
    width: 90%;
    display: flex;
    align-items: center;
}

.multiselect-answer-card-image-container {
    margin-right: 15px;
}

.multiselect-answer-card-checkbox-external-element {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    border: 1px black solid;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multiselect-answer-card-checkbox-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: black;
}

.multiselect-answer-card-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}

.multiselect-answer-card-input-field {
    background-color: transparent;
    border: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}

.multiselect-answer-card-input-field:focus {
    outline: none;
}

@media (max-width: 480px) {
    .multiselect-answer-card-container {
        margin-bottom: 0;
    }

    .multiselect-answer-checked-card-container {
        margin-bottom: 0;
    }
}