.platform-exams-and-tests-collecting-packing-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.exams-and-tests-packing-title-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    width: 89%;
}

.exams-and-tests-packing-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.exams-and-tests-packing-element-container {
    display: flex;
    flex-direction: column;
    height: 80%;
    overflow-y: auto;
  }

@media (max-width: 480px) {
    .platform-exams-and-tests-collecting-packing-full-container {
        align-self: center;
    }

    .exams-and-tests-packing-title-button-row {
        width: 100%;
    }
}