.platform-my-answers-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.platform-my-answers-title-row {
    height: 10%;
    width: 100%;
}

.platform-my-answers-content-container {
    overflow-y: auto;
    height: 90%;
    width: 100%;
}

.platform-my-answers-survey-placeholder {
    height: 237px;
    width: 396px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}

@media (max-width: 480px) {
    .platform-my-answers-full-container {
        align-self: center;
    }

    .platform-my-answers-survey-placeholder {
        display: none;
    }
}