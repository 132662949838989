.platform-my-apointments-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.platform-my-apointments-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

@media (max-width: 480px) {
    .platform-my-apointments-full-container {
        align-self: center;
    }
}