.input-answer-card-container {
  width: 450px;
  height: 100px;
  border-radius: 10px;
  border: 1px rgba(234, 234, 234, 1) solid;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.input-answer-card-filled-container {
  width: 450px;
  height: 100px;
  border-radius: 10px;
  border: 1px rgba(40, 231, 197, 0.2) solid;
  box-shadow: 0px 1px 4px 0px rgba(40, 231, 197, 0.2);
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 231, 197, 0.2);
}

.input-answer-checked-card-container {
  width: 450px;
  height: 100px;
  border-radius: 10px;
  border: 1px transparent solid;
  box-shadow: 0px 1px 4px 0px transparent;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 231, 197, 0.2);
}

.input-answer-card-internal-container {
  width: 90%;
  display: flex;
  align-items: center;
}

.input-answer-card-new-input-field {
  font-size: 18px;
  border: none;
  background-color: transparent;
  width: 100%;
}

.input-answer-card-new-input-field:focus {
  outline: none;
}

.input-answer-card-new-input-field::placeholder {
  color: rgba(95, 95, 95, 1);
  font-size: 400;
  font-size: 18px;
  line-height: 26px;
}

.input-answer-card-icon-card-container {
  display: flex;
  align-items: center;
}

.input-answer-card-card-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-answer-card-email-text {
  width: 530px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 20px;
}

@media (max-width: 480px) {
  .input-answer-card-container {
    width: 100%;
    margin-bottom: 0;
  }

  .input-answer-card-filled-container {
    width: 100%;
    margin-bottom: 0;
  }

  .input-answer-card-icon-card-container {
    width: 100%;
    flex-direction: column;
  }

  .input-answer-card-card-text-container {
    width: 90%;
  }

  .input-answer-card-email-text {
    width: 99%;
  }
}