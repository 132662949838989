.platform-personal-data-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.personal-data-form {
    width: 35%;
}

.personal-data-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.personal-data-input-field:focus {
    outline: none;
}

@media (max-width: 480px) {
    .platform-personal-data-full-container {
        align-self: center;
    }

    .personal-data-form {
        width: 100%;
    }
}