.platform-exams-and-tests-specific-exam-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.platform-exams-and-tests-specific-exam-heading-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 13%;
    width: 100%;
}

.platform-exams-and-tests-specific-exam-content-container {
    width: 100%;
    height: 87%;
    overflow-y: auto;
}

.platform-exams-and-tests-specific-exam-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.platform-exams-and-tests-specific-exam-placeholder {
        width: 32%;
        aspect-ratio: 1 / 1.5;
        margin-bottom: 20px;
}

@media (max-width: 480px) {
    .platform-exams-and-tests-specific-exam-full-container {
        align-self: center;
    }

    .platform-exams-and-tests-specific-exam-cards-container {
        flex-direction: column;
        height: max-content;
        align-items: center;
    }

    .platform-exams-and-tests-specific-exam-placeholder {
        display: none;
    }
}