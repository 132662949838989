.redeem-page-container {
    width: 100%;
    height: 50%;
}

.redeem-page-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.redeem-page-card-width {
    width: 300px;
    margin-right: 20px;
    margin-left: 20px;
}

@media (max-width: 480px) {
    .redeem-page-container {
        width: 100%;
        height: 80%;
    }

    .redeem-page-card-container {
        flex-direction: column;
    }

    .redeem-page-card-width {
        width: 90%;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}