.my-answers-survey-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 237px;
  width: 396px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px rgba(234, 234, 234, 1) solid;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.my-answers-survey-card-internal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 90%;
}

.my-answers-survey-card-upper-element-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px black solid;
}

.my-answers-survey-card-survey-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.my-answers-survey-card-survey-date {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}

.my-answers-survey-card-bottom-element-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
}

.my-answers-survey-card-contents-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.my-answers-survey-card-contents-copy {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

@media (max-width: 480px) {
  .my-answers-survey-card-container {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}