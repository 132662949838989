.quiz-q-home-header {
  width: 90%;
  height: 10%;
  display: flex;
  align-items: center;
}

.quiz-q-home-container {
  width: 450px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.quiz-q-home-footer {
  width: 100%;
  height: 10%;
}

.quiz-q-home-after-purchase-text {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.quiz-q-home-after-purchase-heading {
  text-align: center;
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
}

.quiz-q-home-no-purchase-heading {
  text-align: center;
  font-size: 25px;
  line-height: 35px;
}

.quiz-q-home-after-purchase-copy {
  text-align: center;
  width: 90%;
  font-size: 18px;
  line-height: 26px;
}

.quiz-q-home-no-purchase-copy {
  text-align: center;
  width: 90%;
  font-size: 18px;
  line-height: 26px;
}

.quiz-q-home-no-purchase-text {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.quiz-q-home-confirm-button {
  width: 328px;
  height: 55px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 480px) {
  .quiz-q-home-container {
    width: 90%;
    height: 80%;
  }
}