.collecting-actions-widget-big-container {
    width: 100%;
    height: 315px;
    background-color: rgba(239, 246, 255, 1);
    border: 2px rgba(0, 104, 255, 1) solid;
    border-radius: 20px;
    display: flex;
}

.collecting-actions-widget-small-container {
    width: 100%;
    height: 212px;
    background-color: rgba(239, 246, 255, 1);
    border: 2px rgba(0, 104, 255, 1) solid;
    border-radius: 20px;
    display: flex;
}

.collecting-actions-widget-left-element-container {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.collecting-actions-widget-left-element-internal-container {
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.collecting-actions-widget-left-element-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.collecting-actions-widget-left-element-copy-big-container {
    width: 100%;
    height: 155px;
    display: flex;
    align-items: center;
}

.collecting-actions-widget-left-element-button-desktop {
    width: 250px;
    height: 55px;
    border: none;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.collecting-actions-widget-left-element-button-mobile {
    width: 250px;
    height: 55px;
    border: none;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: none;
}

.collecting-actions-widget-right-element-container {
    height: 100%;
    width: 50%;
    border-radius: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collecting-actions-widget-right-internal-element-container {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.collecting-actions-widget-right-element-image-1 {
    background-image: url("../../../../../../public/images/widgets/collecting-action-widget.svg");
}

.collecting-actions-widget-right-element-image-2 {
    background-image: url("../../../../../../public/images/widgets/register-swab-code-widget.svg");
}

.collecting-actions-widget-right-element-image-3 {
    background-image: url("../../../../../../public/images/widgets/pickup-request-widget.svg");
}

.collecting-actions-widget-right-element-image-4 {
    background-image: url("../../../../../../public/images/widgets/pickup-request-widget.svg");
}

@media (max-width: 480px) {
    .collecting-actions-widget-big-container {
        width: 99%;
        flex-direction: column;
        height: 550px;
    }

    .collecting-actions-widget-small-container {
        width: 99%;
        flex-direction: column;
        height: 450px;
    }

    .collecting-actions-widget-left-element-container {
        height: 60%;
        width: 100%;
    }

    .collecting-actions-widget-left-element-internal-container {
        justify-content: space-evenly;
    }

    .collecting-actions-widget-right-element-container {
        height: 40%;
        width: 100%;
        border-radius: 0 0 20px 20px;
    }

    .collecting-actions-widget-left-element-button-desktop {
        display: none;
    }

    .collecting-actions-widget-left-element-button-mobile {
        display: block;
    }
}