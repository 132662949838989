.important-things-widget-container {
    width: 49%;
    height: 330px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.important-things-widget-internal-container {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.important-things-widget-upper-element {
    width: 100%;
    height: 60px;
}

.important-things-widget-content-container {
    width: 100%;
    height: calc(100% - 60px);
}

.important-things-widget-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.important-things-widget-author {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: rgba(0, 104, 255, 1);
}

.important-things-widget-content {
    width: 100%;
    height: 100%;
}

.important-things-widget-content-single-item {
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    margin-left: 20px;
}

@media (max-width: 480px) {
    .important-things-widget-container {
        width: 99%;
        margin-bottom: 20px;
    }
}