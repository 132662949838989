.platform-experts-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.experts-cards-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: max-content;
}

.experts-next-apointments {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    height: max-content;
}

.experts-book-next-apointment-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    height: 100%;
    width: 50%;
}

.experts-book-next-apointment-info-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.experts-book-next-apointment-image {
    height: 100%;
    width: 50%;
    background-color: rgba(247, 247, 247, 1);
    border-radius: 0px 20px 20px 0px;
}

.experts-reminder-imminent {
    display: flex;
    width: 100%;
    height: 230px;
    border-radius: 20px;
    border: 2px rgba(0, 104, 255, 1) solid;
    background-color: rgba(239, 246, 255, 1);
}

.experts-reminders-infos-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    width: 40%;
    height: 100%;
}

.experts-reminders-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 60%;
    height: 100%;
    margin-right: 10px;
}

.experts-reminder {
    display: flex;
    width: 720px;
    height: 187px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.experts-blue-text {
    color: rgba(0, 104, 255, 1);
}

@media (max-width: 2000px) {
    .experts-cards-container {}
}

@media (max-width: 1110px) {
    .experts-cards-container {
        flex-direction: column;
        height: max-content;
    }
}

@media (max-width: 996px) {
    .experts-reminder-imminent {
        width: 100%;
    }

    .experts-reminder {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .platform-experts-full-container {
        align-self: center;
    }

    .experts-book-next-apointment-container {
        width: 99%;
        flex-direction: column;
        height: 500px;
    }

    .experts-book-next-apointment-info {
        width: 100%;
        height: 50%;
    }

    .experts-book-next-apointment-image {
        width: 100%;
        height: 50%;
        border-radius: 0px 0px 20px 20px;
    }

    .experts-reminder-imminent {
        width: 99%;
        flex-direction: column;
        height: max-content;
    }

    .experts-reminders-infos-box {
        width: 90%;
    }

    .experts-reminders-buttons {
        width: 90%;
        align-self: center;
    }

    .experts-reminder {
        width: 99%;
        flex-direction: column;
        height: max-content;
        margin-bottom: 20px;
    }
}