.reward-page-new-choose-product-container {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

.reward-page-new-choose-product-section-1-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.reward-page-new-choose-product-section-1-internal-container {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.reward-page-new-choose-product-section-2-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.reward-page-new-choose-product-section-2-internal-container {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.reward-page-new-choose-product-top-row {
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-items: center;
}

.reward-page-new-choose-product-copy {
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
}

.reward-page-new-choose-product-toggle-text {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    width: fit-content;
}

.reward-page-new-choose-product-products-container {
    width: 100%;
    height: calc(100% - 270.594px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
}

.reward-page-new-choose-product-products-row {
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reward-page-new-choose-product-products-row-mobile {
    display: none;
}

.reward-page-new-choose-product-products-no-test-products-row-mobile {
    display: none;
}

.reward-page-new-choose-product-products-no-test-container {
    width: 100%;
    height: calc(100% - 270.594px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow-y: auto;
}

.reward-page-new-choose-product-products-no-test-products-row {
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reward-page-new-choose-product-products-no-bundle-container {
    width: 100%;
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.reward-page-new-section-2-choose-products-product-row {
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reward-page-new-section-2-choose-products-product-row-mobile {
    display: none;
}

.reward-page-new-info-box {
    width: 360px;
    height: 106px;
    border-radius: 10px;
    border: 1px black solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reward-page-new-info-box-internal {
    width: 85%;
    height: 80%;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.reward-page-new-buttons-row {
    width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reward-page-i-dont-care-button {
    width: 289px;
    border: none;
    background-color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.reward-page-to-insert-code-button {
    width: 325px;
    height: 60px;
    border: 1px black solid;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.reward-page-new-section-2-buttons-row {
    width: 1100px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.reward-page-new-section-2-info-container {
    width: 1100px;
    height: 65px;
    border-radius: 10px;
    border: 1px black solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reward-page-new-section-2-info-internal-container {
    width: 95%;
    width: 400;
    font-size: 15px;
    line-height: 20px;
}

.reward-page-new-section-2-bottom-buttons-row {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
}

.reward-page-buy-plan-button {
    width: 325px;
    height: 60px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-right: 20px;
}

.reward-page-choose-product-relative-container {
    position: absolute;
    width: calc(100% - 40px);
    height: fit-content;
    top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reward-page-new-choose-product-simple-logo {
    display: none;
}

@media (max-width: 480px) {
    .reward-page-new-choose-product-copy {
        font-size: 16px;
        line-height: 24px;
    }

    .reward-page-new-choose-product-toggle-text {
        font-size: 14px;
        line-height: 40px;
    }

    .reward-page-new-choose-product-full-logo {
        display: none;
    }

    .reward-page-new-choose-product-simple-logo {
        display: block;
    }

    .reward-page-new-choose-product-products-container {
        align-items: start;
        justify-content: start;
    }

    .reward-page-new-choose-product-products-no-test-products-row,
    .reward-page-new-choose-product-products-row,
    .reward-page-new-section-2-choose-products-product-row {
        display: none;
    }

    .reward-page-new-choose-product-products-row-mobile {
        width: 930px;
        margin-left: 20px;
        margin-right: 20px;
        height: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .reward-page-new-choose-product-products-no-test-products-row-mobile {
        width: 930px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .reward-page-new-section-2-choose-products-product-row-mobile {
        width: 930px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .reward-page-new-choose-product-products-no-test-container {
        align-items: start;
        justify-content: start;
    }

    .reward-page-new-choose-product-products-no-bundle-container {
        align-items: start;
    }

    .reward-page-new-buttons-row {
        width: 100%
    }

    .reward-page-new-section-2-buttons-row {
        width: calc(100% - 30px);
    }
}