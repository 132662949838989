.reward-page-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.reward-page-section-1-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.reward-page-section-1-title-block {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 15%;
}

.reward-page-section-1-expert-container {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reward-page-section-1-buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 1200px;
  height: 15%;
  margin-bottom: 20px;
}

.reward-page-section-2-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.reward-page-section-2-products-external-container {
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  overflow-y: auto;
}

.reward-page-section-2-products-internal-container {
  height: 85%;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reward-page-section-2-buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 1200px;
  height: 15%;
  margin-bottom: 20px;
}

.reward-page-show-more-button {
  margin-left: 20px;
  height: 60px;
  border-radius: 5px;
  border: none;
  background-color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.reward-page-already-purchased-section-1-button {
  margin-right: 20px;
  width: 325px;
  height: 60px;
  border-radius: 5px;
  border: 1px black solid;
  background-color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.reward-page-go-to-bundle-button {
  width: 686px;
  height: 60px;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.reward-page-already-purchased-section-2-button {
  width: 489px;
  height: 60px;
  border-radius: 5px;
  border: 1px black solid;
  background-color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.reward-page-purchase-button {
  width: 325px;
  height: 55px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 18.75px;
}