.platform-dashboard-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.dashboard-title-row {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dashboard-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
}

.dashboard-element-100x2 {
    height: 30%;
    width: 100%;
}

.dashboard-element-66x100 {
    height: 100%;
    width: 66%;
}

.dashboard-element-33x100 {
    height: 100%;
    width: 33%;
}

.dashboard-element-100x1 {
    height: 15%;
    width: 100%;
}

.dashboard-wrap-row-425 {
    width: 100%;
    height: 425px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.dashboard-wrap-row-924 {
    width: 100%;
    height: 924px;
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    flex-wrap: wrap;
}

.desktop-only {

}

@media (max-width: 480px) {
    .platform-dashboard-full-container {
        align-self: center;
    }

    .desktop-only {
        display: none;
    }

    .dashboard-title {
        font-size: 30px;
        line-height: 30px;
    }
}