.my-purchases-cancel-sub-dialog-container {
    position: absolute;
    width: 572px;
    height: 350px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-purchases-cancel-sub-dialog-internal-container {
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.my-purchases-cancel-sub-dialog-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

textarea {
    resize: none;
    outline: none;
    width: 100%;
    height: 87px;
    border-radius: 5px;
    border: 1px black solid;
}

.my-purchases-cancel-sub-dialog-buttons-row {
    display: flex;
    justify-content: space-between;
}

.my-purchases-cancel-sub-dialog-undo-button {
    width: 240px;
    height: 55px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.my-purchases-cancel-sub-dialog-confirm-button {
    width: 240px;
    height: 55px;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 58, 55, 0.5);
    border: 2px rgba(255, 58, 55, 0.5) solid;
}