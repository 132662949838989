.checkup-survey-widget-container {
    width: 100%;
    height: 247px;
    border-radius: 20px;
    display: flex;
    border: 2px rgba(0, 104, 255, 1) solid;
    background-color: rgba(239, 246, 255, 1);
}

.checkup-survey-widget-left-element-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkup-survey-widget-widget-left-element-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkup-survey-widget-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.checkup-survey-widget-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.checkup-survey-widget-answer-button {
    width: 200px;
    height: 55px;
    color: white;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.checkup-survey-widget-right-element-container {
    width: 50%;
    height: 100%;
    border-radius: 0px 20px 20px 0px;
    background-color: rgba(214, 227, 247, 1);
}

@media (max-width: 480px) {
    .checkup-survey-widget-container {
        width: 99%;
        flex-direction: column;
        height: 450px;
    }

    .checkup-survey-widget-left-element-container {
        width: 100%;
        height: 50%;
    }

    .checkup-survey-widget-right-element-container {
        width: 100%;
        height: 50%;
        border-radius: 0 0 20px 20px;
    }
}