.my-purchases-my-product-card {
    display: flex;
    width: 600px;
    height: 100px;
    border-radius: 10px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
}

@media (max-width: 480px) {
    .my-purchases-my-product-card {
        width: 99%;
    }
}