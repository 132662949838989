.upload-file-dialog-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 90%;
    visibility: hidden;
}

.upload-dialog-mobile-radio-container {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 1px black solid;
    cursor: pointer;
}

.upload-dialog-mobile-radio-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: black;
}

.upload-dialog-mobile-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.upload-dialog-mobile-input-field:focus {
    outline: none;
}

@media (max-width: 480px) {
    .upload-file-dialog-container-mobile {
        visibility: visible;
    }
}