.platform-my-purchases-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.my-purchases-redeem-new-product-card {
    display: flex;
    align-items: center;
    width: 600px;
    height: 100px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
    background-color: white;
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.my-purchases-my-purchases-container {
    margin-top: 3%;
    height: 79%;
}

.my-purchases-delivery-box {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: max-content;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: rgba(234, 234, 234, 1);
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
}

@media (max-width: 480px) {
    .platform-my-purchases-full-container {
        align-self: center;
    }

    .my-purchases-redeem-new-product-card {
        width: 99%;
    }

    .my-purchases-my-purchases-container {
        margin-top: 10%;
        height: 72%;
    }

    .my-purchases-delivery-box {
        width: 99%;
    }
}