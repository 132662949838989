.choose-product-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.choose-product-upper-element-container {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.choose-product-upper-element-heading {
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
}

.choose-product-upper-element-copy {
  font-size: 18px;
  line-height: 24px;
  width: 456px;
}

.choose-product-form-container {
  width: 100%;
  height: 60%;
}

.choose-product-cards-container {
  display: flex;
  justify-content: center;
  align-items: start;
  overflow-y: auto;
  height: 80%;
}

.choose-product-cards-internal-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.choose-product-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20%;
}

.choose-product-button {
  height: 55px;
  width: 328px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .choose-product-container {
    width: 90%;
    height: 100%;
  }
}