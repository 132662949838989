.under-the-age-page-element-container {
    width: 512px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.under-the-age-page-copy-container {
    width: 512px;
    height: 282px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.under-the-age-page-copy-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
}

.under-the-age-page-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
}

.under-the-age-page-buttons-container {
    width: 512px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.under-the-age-page-write-to-us-button {
    width: 328px;
    height: 55px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.under-the-age-page-to-the-homepage-button {
    width: 328px;
    height: 55px;
    border-radius: 5px;
    border: 1px black solid;
    background-color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}