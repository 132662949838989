.payment-method-alert-dialog-container {
    width: 496px;
    height: 150px;
    background-color: white;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-method-alert-dialog-internal-container {
    height: 130px;
    width: 470px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}