.quiz-q-widget-container {
    width: 100%;
    height: 230px;
    border-radius: 20px;
    display: flex;
    border: 1px black solid;
    background-color: white;
}

.quiz-q-widget-left-element-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quiz-q-widget-left-element-internal-container {
    width: 90%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.quiz-q-widget-answer-button {
    width: 250px;
    height: 55px;
    background-color: white;
    border: 1px solid black;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.quiz-q-widget-right-element-container {
    width: 50%;
    height: 100%;
    border-radius: 0px 20px 20px 0px;
    background-image: url("../../../../../../public/images/widgets/quiz-q-widget.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #F0FFF2;
}

.quiz-q-widget-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.quiz-q-widget-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 480px) {
    .quiz-q-widget-container {
        width: 99%;
        height: 600px;
        flex-direction: column;
    }

    .quiz-q-widget-left-element-container {
        width: 100%;
        height: 60%;
    }

    .quiz-q-widget-right-element-container {
        width: 100%;
        height: 40%;
        border-radius: 0px 0px 20px 20px;
    }
}