.reward-page-new-internal-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reward-page-new-logo {
    height: 50px;
}

.reward-page-new-content {
    width: 100%;
    height: calc(100% - 105px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
}

.reward-page-new-copy {
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    width: 1064px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
}

.reward-page-new-expert-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.reward-page-new-expert-heading {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
}

.reward-page-new-your-goal-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
}

.reward-page-new-your-goal-area {
    margin-top: 40px;
    margin-bottom: 60px;
}

.reward-page-new-your-goal-container {
    display: flex;
    align-items: center;
    width: 621px;
    height: 78px;
    border: none;
    background-color: rgba(139, 174, 225, 1);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.reward-page-new-your-goal-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: white;
    margin-left: 20px;
}

.reward-page-new-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55px;
}

.reward-page-new-continue-button {
    width: 325px;
    height: 55px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: white;
}

@media (max-width: 480px) {
    .reward-page-new-internal-container {
        width: 100%;
    }

    .reward-page-new-copy {
        font-size: 20px;
        width: calc(100% - 30px);
    }

    .reward-page-new-your-goal-area {
        width: calc(100% - 30px);
    }

    .reward-page-new-your-goal-container {
        width: 100%;
    }

    .reward-page-new-expert-heading {
        width: calc(100% - 30px);
    }

    .reward-page-new-button-container {
        align-items: center;
        width: calc(100% - 30px);
        height: 60px;
    }

    .reward-page-new-continue-button {
        width: 100%;
        height: 60px;
    }
}