.platform-plan-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.plan-your-protocol-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.plan-your-protocol {
    width: 100%;
    height: 100px;
    border-radius: 20px;
    background-color: rgba(139, 174, 225, 1);
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.plan-your-protocol-text {
    font-weight: 400;
    font-size: 30px;
    line-height: 20px;
    text-transform: uppercase;
    color: white;
    margin-left: 20px;
}

.plan-expert-area-container {
    display: flex;
    justify-content: space-between;
}

.plan-plans-area-container {
    display: flex;
    justify-content: space-between;
}

.plan-plans-area-pdf-external-container {
    width: 49%;
}

.plan-plans-area-pdf-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.plan-important-key-points-container {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 480px) {
    .platform-plan-full-container {
        align-self: center;
    }

    .plan-expert-area-container {
        flex-direction: column;
    }

    .plan-plans-area-container {
        flex-direction: column;
    }

    .plan-important-key-points-container {
        flex-direction: column;
        align-items: center;
    }

    .plan-plans-area-pdf-external-container {
        width: 99%;
    }
}