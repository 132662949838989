.experts-widget-container {
    height: 425px;
    width: 48%;
    border: 1px rgba(234, 234, 234, 1) solid;
    border-radius: 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.experts-widget-smaller-container {
    height: 230px;
    width: 48%;
    border: 1px rgba(234, 234, 234, 1) solid;
    border-radius: 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.experts-widget-internal-container {
    width: 95%;
    height: 90%;
}

.experts-widget-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.experts-widget-experts-container {
    width: 100%;
    height: calc(100% - 40px);
}

.experts-widget-expert-container {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.experts-widget-expert-container-100-percent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.experts-widget-expert-internal-container {
    width: 99%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.experts-widget-expert-image-container {
    width: 118px;
    height: 118px;
    border-radius: 5px;
    border: 2px black solid;
    display: flex;
    align-items: center;
}

.experts-widget-expert-image {
    width: 118px;
    height: 118px;
    z-index: -1;
}

.experts-widget-expert-info-container {
    width: 400px;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.experts-widget-expert-info-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.experts-widget-expert-position {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.experts-widget-expert-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.experts-widget-expert-role {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 104, 255, 1);
}

.experts-widget-expert-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    overflow-y: auto;
}

@media (max-width: 480px) {
    .experts-widget-container {
        width: 99%;
        height: 900px;
        margin-bottom: 35px;
    }

    .experts-widget-smaller-container {
        width: 99%;
        margin-bottom: 35px;
        height: 470px;
    }

    .experts-widget-internal-container {
        height: 98%;
    }

    .experts-widget-expert-internal-container {
        flex-wrap: wrap;
    }
}