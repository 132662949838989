.payment-method-add-new-payment-return-container {
    width: 800px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.payment-method-add-new-payment-return-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
}

.payment-method-add-new-payment-return-copy {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
}

.payment-method-add-new-payment-return-button {
    width: 328px;
    height: 55px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}