.email-registration-buttons-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    width: 90%;
}

.email-registration-central-element-external-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    overflow-y: auto;
}

.email-registration-central-element-internal-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 392px;
    height: 100%;
    background-color: white;
}

.email-registration-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.email-registration-input-field:focus {
    outline: none;
}

.email-registration-checkbox-container {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    border: 1px black solid;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.email-registration-checkbox-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: black;
}

@media (max-height: 880px) {
    .email-registration-central-element-internal-container {
        justify-content: flex-start;
    }
}