.platform-my-purchases-redeem-choose-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.my-purchases-redeem-choose-confirm-button {
    width: 328px;
    height: 55px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
}

@media (max-width: 480px) {
    .platform-my-purchases-redeem-choose-full-container {
        align-self: center;
    }

    .my-purchases-redeem-choose-confirm-button {
        width: 100%;
    }
}