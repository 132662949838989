.insert-product-code-buttons-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    width: 90%;
}

.insert-product-code-logo-one-div {
    visibility: hidden;
}

.insert-product-code-logo-two-div {
    visibility: visible;
}

.insert-product-code-container {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.insert-product-code-internal-container {
    width: 80%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.insert-product-code-copy {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    width: 400px;
}

@media (max-width: 480px) {
    .insert-product-code-logo-one-div {
        visibility: visible;
    }

    .insert-product-code-logo-two-div {
        visibility: hidden;
    }

    .insert-product-code-container {
        width: 100%;
        height: 80%;
    }

    .insert-product-code-internal-container {
        width: 100%;
    }

    .insert-product-code-copy {
        width: calc(100% - 30px);
    }
}