.new-product-card-external-container {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.new-product-card-container {
    width: 360px;
    height: 513px;
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(243, 247, 253, 1);
}

.new-product-card-internal-container {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.new-product-card-no-test-external-container {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.new-product-card-section-1-no-test-container {
    width: 360px;
    height: 440px;
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(243, 247, 253, 1);
}

.new-product-card-no-test-container {
    width: 360px;
    height: 440px;
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(243, 247, 253, 1);
}

.new-product-card-no-test-internal-container {
    width: 310px;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
}

.new-product-card-no-test-most-selected-text {
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 104, 255, 1);
    text-transform: uppercase;
    width: 90%;
}

.new-product-card-most-purchased {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 104, 255, 1);
}

.new-product-card-product-tag-blue {
    width: 198px;
    height: 38px;
    background-color: rgba(0, 104, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: white;
}

.new-product-card-product-tag {
    width: 198px;
    height: 38px;
    background-color: rgba(214, 227, 247, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
}

.new-product-card-product-tag-bigger {
    width: 260px;
    height: 38px;
    background-color: rgba(214, 227, 247, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
}

.new-product-card-copy {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    height: 98px;
}

.new-product-card-price-container {
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.new-product-card-price {
    width: 100%;
    font-weight: 400;
    font-size: 70px;
    line-height: 55px;
    color: rgba(0, 104, 255, 1);
}

.new-product-card-price-per-month {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
}

.new-product-card-buttons-container {
    width: 310px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.new-product-card-no-text-buttons-container {
    width: 310px;
    height: 143.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.new-product-card-product-category {
    width: 310px;
    height: 65px;
    border-radius: 5px;
    border: 1px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}

.new-product-card-price-copy {
    width: 210px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.new-product-card-button {
    width: 310px;
    height: 55px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.barred-text {
    text-decoration: line-through;
}

.new-product-card-no-bundle-external-container {
    width: 360px;
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.new-product-card-no-bundle-container {
    width: 360px;
    height: 310px;
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    background-color: rgba(243, 247, 253, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-no-bundle-internal-container {
    width: 310px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.new-product-card-no-bundle-price-and-product {
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.new-product-card-no-bundle-price-info-button-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 312px;
}

.new-product-card-no-bundle-single-item-container {
    width: 310px;
    height: 138px;
    border-radius: 5px;
    border: 1px rgba(139, 174, 225, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-no-bundle-single-item-internal-container {
    width: 95%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}

.new-product-card-no-bundle-double-items-container {
    width: 310px;
    height: 138px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.new-product-card-no-bundle-double-items-single-element-container {
    width: 310px;
    height: 65px;
    border-radius: 5px;
    border: 1px rgba(139, 174, 225, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-no-bundle-double-items-single-element-internal-container {
    width: 95%;
    height: 95%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}

.new-product-card-plus {
    font-weight: 400;
    font-size: 50px;
    line-height: 28px;
}

.new-product-card-bundle-price-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.new-product-card-bundle-old-price {
    font-weight: 400;
    font-size: 30px;
    line-height: 28px;
    color: black;
    text-decoration: line-through;
}

.new-product-card-bundle-copy {
    width: 200px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.new-product-card-bundle-price {
    color: rgba(0, 104, 255, 1);
    margin-bottom: 10px;
}

.new-product-card-mobile-external-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-product-card-mobile-most-selected {
    align-self: flex-start;
    color: rgba(0, 104, 255, 1);
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    margin-left: 20px;
    margin-bottom: 10px;
}

.new-product-card-mobile-plus {
    font-weight: 400;
    font-size: 35px;
}

.new-product-card-mobile-sub-container-bigger {
    width: 300px;
    height: 378px;
    margin-bottom: 10px;
    background-color: rgba(243, 247, 253, 1);
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-sub-container {
    width: 300px;
    height: 238px;
    margin-bottom: 10px;
    background-color: rgba(243, 247, 253, 1);
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-sub-container-most-selected-bigger {
    width: 300px;
    height: 376px;
    margin-bottom: 10px;
    background-color: rgba(227, 236, 249, 1);
    border-radius: 10px;
    border: 3px rgba(0, 104, 255, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-sub-container-most-selected {
    width: 300px;
    height: 236px;
    margin-bottom: 10px;
    background-color: rgba(227, 236, 249, 1);
    border-radius: 10px;
    border: 3px rgba(0, 104, 255, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-sub-internal-container {
    width: 90%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.new-product-card-mobile-test-container {
    margin-top: 10px;
    width: 300px;
    height: 150px;
    background-color: rgba(243, 247, 253, 1);
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-test-container-bigger {
    margin-top: 10px;
    width: 300px;
    height: 308px;
    background-color: rgba(243, 247, 253, 1);
    border-radius: 10px;
    border: 2px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-test-container-most-selected {
    margin-top: 10px;
    width: 300px;
    height: 148px;
    background-color: rgba(227, 236, 249, 1);
    border-radius: 10px;
    border: 3px rgba(0, 104, 255, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-test-container-most-selected-bigger {
    margin-top: 10px;
    width: 300px;
    height: 306px;
    background-color: rgba(227, 236, 249, 1);
    border-radius: 10px;
    border: 3px rgba(0, 104, 255, 1) solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-test-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.new-product-card-mobile-sub-tag-blue {
    background-color: rgba(0, 104, 255, 1);
    border-radius: 5px;
    width: fit-content;
    color: white;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 10px 20px;
    align-self: flex-start;
}

.new-product-card-mobile-sub-tag {
    background-color: rgba(214, 227, 247, 1);
    border-radius: 5px;
    width: fit-content;
    color: black;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 10px 20px;
    align-self: flex-start;
}

.new-product-card-mobile-sub-copy {
    font-size: 9px;
    line-height: 15px;
}

.new-product-card-mobile-sub-price-container {
    align-self: flex-start;
}

.new-product-card-mobile-sub-price {
    color: rgba(0, 104, 255, 1);
    font-weight: 400;
    font-size: 50px;
}

.new-product-card-mobile-sub-price-per-month {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
}

.new-product-card-mobile-test-price {
    font-weight: 400;
    font-size: 50px;
    color: rgba(0, 104, 255, 1);
}

.new-product-card-mobile-bundle-copy {
    width: 200px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.new-product-card-mobile-purchase-button {
   width: 300px;
   height: 55px;
   color: white;
   border: none;
   border-radius: 5px;
   text-transform: uppercase;
   font-weight: 700;
   font-size: 16px;
   line-height: 19px;
}

.new-product-card-mobile-price-copy {
    width: 210px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.new-product-card-mobile-bundle-contents-container {
    width: 100%;
    height: calc(351.91px / 3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.new-product-card-mobile-product-category {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    border: 1px rgba(139, 174, 225, 1) solid;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.new-product-card-mobile-test-single-item-container {
    width: 100%;
    height: 138px;
    border-radius: 5px;
    border: 1px rgba(139, 174, 225, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-product-card-mobile-test-single-item-internal-container {
    width: 95%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}