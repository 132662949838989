.change-password-dialog-external-container {
    width: 500px;
    height: 250px;
    background-color: white;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-password-dialog-internal-container {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.change-password-dialog-close-button-row {
    display: flex;
    justify-content: flex-end;
}

.change-password-dialog-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}