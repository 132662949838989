.expert-comments-hints-widget-container {
    width: 620px;
    height: 330px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert-comments-hints-widget-internal-container {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.expert-comments-hints-widget-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.expert-comments-hints-widget-author {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: rgba(0, 104, 255, 1);
}

.expert-comments-hints-widget-single-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.expert-comments-hints-widget-multiple-contents {
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
}

@media (max-width: 480px) {
    .expert-comments-hints-widget-container {
        width: 99%;
        height: max-content;
        margin-bottom: 20px
    }

    .expert-comments-hints-widget-internal-container {
        padding: 20px;
    }
}