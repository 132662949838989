.payment-method-add-new-method-external-container {
    display: flex;
    align-items: center;
    width: 600px;
    height: 100px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
    background-color: white;
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 770px) {
    .payment-method-add-new-method-external-container {
        width: 99%;
    }
}