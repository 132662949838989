.choose-product-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 256px;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
    background-color: white;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.choose-product-card-checked-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 256px;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
    background-color: rgba(40, 231, 197, 0.2);
    border: 1px rgba(40, 231, 197, 0.2) solid;
    box-shadow: 0px 1px 4px 0px rgba(40, 231, 197, 0.2);
}

.choose-product-card-internal-container {
    width: 90%;
    height: 90%;
}

.choose-product-card-upper-element-container {
    display: flex;
    line-height: 0;
    align-items: center;
    height: 25%;
    margin-left: 4%;
}

.choose-product-card-divider {
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
    background-color: black;
    align-self: center;
    height: 1px;
}

.choose-product-card-bottom-element-container {
    margin-top: 20px;
    height: 70px;
    margin-left: 4%;
}

@media (max-width: 480px) {
    .choose-product-card-container {
        width: 100%;
        height: 250px;
        margin-top: 10%;
        margin-bottom: 5%;
    }
}