.info-dialog-container {
    width: 758px;
    height: 259px;
    border-radius: 20px;
    background-color: white;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-dialog-internal-container {
    height: 80%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-dialog-title-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info-dialog-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.info-dialog-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
}