.platform-my-answers-edit-answers-full-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.platform-my-answers-edit-answers-answers-card-big-container {
    width: 100%;
    height: 60%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.platform-my-answers-edit-answers-answers-card-container {
    width: 70%;
    height: 100%;
    align-self: center;
}

.platform-my-answers-edit-answers-buttons-row {
    width: 70%;
    height: 15%;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}