.expert-details-dialog-container {
    width: 741px;
    height: 562px;
    position: absolute;
    background-color: white;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.expert-details-dialog-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.expert-details-photo-name-row {
    display: flex;
    width: 100%;
    height: 120px;
    justify-content: space-between;
}

.expert-details-dialog-photo-container {
    height: 110px;
    width: 110px;
    border: 2px black solid;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expert-details-dialog-photo {
    width: 110px;
    z-index: -1;
}

.expert-details-dialog-name-button-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: calc(100% - 160px);
}

.expert-details-dialog-expert-container {
    align-self: flex-end;
}

.expert-details-dialog-expert-position {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
}

.expert-details-dialog-expert-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
}

.expert-details-dialog-expert-role {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: rgba(0, 104, 255, 1);
}

.experts-details-dialog-tags-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    height: 100px;
}

.expert-details-dialog-bio-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
}

.experts-details-dialog-box {
    width: 100%;
    overflow-y: auto;
}

@media (max-width: 480px) {
    .expert-details-dialog-container {
        width: calc(100% - 30px);
        height: 700px;
    }
}