.platform-full-screen-no-navbar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(100% - 100px);
    height: 100%;
    align-self: flex-end;
}

.platform-final-element-container {
    width: 89%;
    height: 100%;
}

@media (max-width: 480px) {
    .platform-full-screen-no-navbar-container {
        height: calc(100% - 100px);
        width: 100%;
        position: absolute;
        top: 0px;
    }

    .platform-final-element-container {
        width: 100%;
    }
}






.prova {
    color: red;
}