.forgot-password-buttons-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    width: 90%;
}

.forgot-password-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.forgot-password-input-field:focus {
    outline: none;
}