.your-plan-widget-container {
    height: 425px;
    width: 48%;
    border: 1px rgba(234, 234, 234, 1) solid;
    border-radius: 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.your-plan-widget-upper-element-container {
    width: 100%;
    height: calc(100% - 282px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.your-plan-widget-upper-element-internal-container {
    height: 80%;
    width: 95%;
    display: flex;
    flex-direction: column;
}

.your-plan-widget-upper-element-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.your-plan-widget-upper-element-copy {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.your-plan-widget-bottom-element-container {
    width: 100%;
    height: 282px;
    background-color: #F0FFF2;
    background-image: url("../../../../../../public/images/widgets/your-plan-widget.svg");
    background-repeat: no-repeat;
    background-position: bottom center;
    position: relative;
    border-radius: 0 0 20px 20px;
}

.your-plan-widget-bottom-element-button {
    width: 251px;
    height: 55px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px black solid;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

@media (max-width: 480px) {
    .your-plan-widget-container {
        width: 99%;
        margin-bottom: 35px;
    }
}