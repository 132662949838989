.parameter-widget-container {
    width: 195px;
    height: 195px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.parameter-widget-internal-container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.parameter-widget-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
}

.parameter-widget-image {
    height: 93px;
    display: flex;
    justify-content: center;
}

.parameter-widget-value {
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    display: flex;
    justify-content: center;
}