.reward-page-expert-card-container {
    width: 621px;
    height: 297px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reward-page-expert-card-internal-container {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reward-page-expert-card-photo-info-container {
    display: flex;
    justify-content: space-between;
}

.reward-page-expert-card-photo-container {
    height: 110px;
    width: 110px;
    border: 2px black solid;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.reward-page-expert-card-photo {
    height: 110px;
    width: 110px;
    z-index: -1;
}

.reward-page-expert-card-info-container {
    width: calc(100% - 154px);
    height: 159px;
    display: flex;
    flex-direction: column;
}

.reward-page-expert-card-info-button-container {
    display: flex;
    justify-content: flex-end;
}

.reward-page-expert-card-tags-container {
    display: flex;
    flex-wrap: wrap;
}

.reward-page-expert-card-info-button {
    width: 167px;
    height: 55px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

@media (max-width: 480px) {
    .reward-page-expert-card-container {
        width: calc(100% - 30px);
    }

    .reward-page-expert-card-info-container {
        overflow-y: auto;
    }
}