.expert-card-container {
    width: 750px;
    height: 195px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert-card-internal-container {
    width: 95%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expert-card-photo-container {
    height: 110px;
    width: 110px;
    border: 2px black solid;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.expert-card-photo {
    width: 110px;
    height: 110px;
    z-index: -1;
}

.expert-card-info-container {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 110px);
    height: 100%;
}

.expert-card-info-internal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 100%;
}

.experts-card-tags-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

.experts-card-buttons-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.experts-card-info-button {
    height: 55px;
    width: 145px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
}

.experts-purchase-button {
    height: 55px;
    width: 250px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
}

@media (max-width: 2000px) {
    .expert-card-container {
        width: 600px
    }
}

@media (max-width: 1610px) {
    .expert-card-container {
        width: 500px;
    }

    .experts-card-info-button {
        width: 120px;
        font-size: 12px;
    }

    .experts-purchase-button {
        width: 220px;
        font-size: 12px;
    }
}

@media (max-width: 1360px) {
    .expert-card-container {
        width: 400px;
    }

    .experts-card-info-button {
        width: 100px;
        font-size: 10px;
    }

    .experts-purchase-button {
        width: 150px;
        font-size: 10px;
    }
}

@media (max-width: 1110px) {
    .expert-card-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .experts-card-info-button {
        width: 145px;
        font-size: 14px;
    }

    .experts-purchase-button {
        width: 250px;
        font-size: 14px;
    }
}

@media (max-width: 795px) {
    .experts-card-info-button {
        font-size: 12px;
        width: 120px
    }

    .experts-purchase-button {
        font-size: 12px;
        width: 220px;
    }
}

@media (max-width: 720px) {
    .experts-card-info-button {
        font-size: 10px;
        width: 100px;
    }

    .experts-purchase-button {
        font-size: 10px;
        width: 200px;
    }
}

@media (max-width: 480px) {
    .expert-card-container {
        width: 99%;
    }

    .experts-card-info-button {
        font-size: 10px;
        width: 85px;
        height: 40px;
    }

    .experts-purchase-button {
        font-size: 10px;
        width: 120px;
        height: 40px;
    }
}