.quiz-q-new-answers-container {
    width: 100%;
    height: 60%;
    overflow-y: auto;
    display: flex;
    align-items: start;
    justify-content: center;
}

.quiz-q-new-break-page-container {
    width: 100%;
    height: 70%;
    overflow-y: auto;
    display: flex;
    align-items: start;
    justify-content: center;
}

.quiz-q-new-answers-internal-container {
    width: 1500px;
    height: fit-content;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.quiz-q-new-break-page-internal-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quiz-q-new-break-page-internal-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 400px;
}

.quiz-q-new-break-page-internal-element-blue-text {
    font-weight: 700;
    font-size: 15px;
    line-height: 35px;
    color: rgba(0, 104, 255, 1);
    text-transform: uppercase;
}

.quiz-q-new-break-page-internal-element-black-text {
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
}

.quiz-q-new-break-page-internal-element-copy {
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    width: 558px;
}

.quiz-q-new-buttons-row {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quiz-q-new-internal-buttons-row {
    width: 70%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quiz-q-new-back-button {
    width: 122px;
    height: 60px;
    border-radius: 5px;
    border: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.quiz-q-new-back-button-arrow {
    line-height: 0;
    margin-right: 2px;
}

.quiz-q-new-back-button-text {
    margin-left: 2px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

.quiz-q-new-confirm-button {
    width: 250px;
    height: 60px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.quiz-q-new-holifya-partial-logo {
    display: none;
}

@media (max-width: 480px) {
    .quiz-q-new-answers-internal-container {
        width: 100%;
    }

    .quiz-q-new-holifya-full-logo {
        display: none;
    }

    .quiz-q-new-holifya-partial-logo {
        display: block;
    }

    .quiz-q-new-buttons-row {
        width: calc(100% - 30px);
    }

    .quiz-q-new-internal-buttons-row {
        width: 100%;
    }

    .quiz-q-new-back-button {
        width: 100px;
    }

    .quiz-q-new-confirm-button {
        width: 200px;
    }

    .quiz-q-new-answers-internal-container {
        margin-top: 0px;
    }

    .quiz-q-new-break-page-internal-element-copy {
        font-size: 25px;
        width: 90%;
    }

    .quiz-q-new-break-page-internal-element {
        width: 100%;
    }
}