.payment-method-your-method-container {
    display: flex;
    align-items: center;
    width: 600px;
    height: 100px;
    border-radius: 10px;
    border: 1px black solid;
    background-color: white;
}

.payment-method-your-method-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
    width: max-content;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}

@media (max-width: 770px) {
    .payment-method-your-method-container {
        width: 99%;
    }
}