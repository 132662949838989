.exams-and-tests-exam-card-container {
    display: flex;
    flex-direction: column;
    height: 411px;
    width: 30%;
    cursor: pointer;
}

.exams-and-tests-exam-card-upper-element-container {
    height: 289px;
    border-radius: 10px 10px 0 0;
    background-color: white;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.exams-and-tests-exam-card-dna-test-image {
    background-image: url("../../../../../../../public/images/examsAndTests/dna-test-card.svg");
    background-repeat: no-repeat;
    background-color: #DAEFFE;
}

.exams-and-tests-exam-card-blood-exams-image {
    background-image: url("../../../../../../../public/images/examsAndTests/blood-exams-card.svg");
    background-repeat: no-repeat;
    background-color: #FFF5F8;
}

.exams-and-tests-exam-card-other-exams-image {
    background-image: url("../../../../../../../public/images/examsAndTests/other-exams-card.svg");
    background-repeat: no-repeat;
    background-color: #FFF5F8;
}

.exams-and-tests-exam-card-bottom-element-container {
    height: calc(100% - 289px);
    border-radius: 0 0 10px 10px;
    background-color: white;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 480px) {
    .exams-and-tests-exam-card-container {
        width: 100%;
        height: 370px;
        margin-bottom: 20px;
    }
}