.platform-my-answers-survey-type-full-container {
    align-self: end;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.my-answers-survey-type-list-header {
    height: 10%;
    width: 89%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.my-answers-survey-type-heading-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.my-answers-survey-type-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.my-answers-survey-type-header-copy {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.my-answers-survey-type-answers-container {
    height: 77%;
    width: 100%;
}

.my-answers-survey-type-answer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 120px;
    border: black 1px solid;
    border-radius: 10px;
    margin-top: 1%;
}

.my-answers-survey-type-answer-internal-container {
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

.my-answers-survey-type-button-row {
    height: 13%;
    display: flex;
    align-items: center;
}

.my-answers-survey-type-confirm-button {
    width: 410px;
    height: 50%;
    border: none;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

@media (max-width: 950px) {
    .my-answers-survey-type-list-header {
        height: 15%;
    }

    .my-answers-survey-type-answers-container {
        height: 72%;
    }
}

@media (max-width: 480px) {
    .platform-my-answers-survey-type-full-container {
        align-self: center;
    }

    .my-answers-survey-type-list-header {
        height: 15%;
        width: 100%;
    }

    .my-answers-survey-type-answers-container {
        height: 72%;
        width: 100%;
    }

    .my-answers-survey-type-answer-container {
        height: 20%;
        margin-top: 3%;
        border-radius: 5px;
    }
}