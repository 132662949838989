.text-exam-card-external-container {
    display: flex;
    flex-direction: column;
    width: 32%;
    aspect-ratio: 1 / 1.5;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
}

.text-exam-card-pdf-container {
    width: 100%;
    height: 80%;
    border-radius: 10px 10px 0 0;
    background-color: rgba(217, 217, 217, 1);
}

.text-exam-card-clickable-banner {
    width: 100%;
    height: 60%;
    position: absolute;
    top: 20%;
    cursor: pointer;
}

.text-exam-card-user-uploader-banner {
    position: absolute;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 /* height_2em       */
.text-exam-card-user-name-label-container {
    width: 100px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background-color: rgba(104, 166, 129, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    text-align: center;
}

.text-exam-card-bottom-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20%;
    border-radius: 0 0 10px 10px;
}

.text-exam-card-bottom-label-internal {
    width: 90%;
    height: 90%;
    display: flex;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.text-exam-card-hyperlink {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
}

.text-exam-card-bottom-label-left-element {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.text-exam-card-iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
}

.text-exam-card-date {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.text-exam-card-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

@media (max-width: 480px) {
    .text-exam-card-external-container {
        width: 90%;
        height: 530px;
    }
}