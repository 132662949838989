.book-apointment-dialog-container {
    width: 758px;
    height: 522px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-apointment-dialog-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.book-apointment-dialog-title-close-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.book-apointment-dialog-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.book-apointment-dialog-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
}

.book-apointment-dialog-email-row {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px black solid;
}

.book-apointment-dialog-email {
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
}

.book-apointment-dialog-copy-text {
    margin-left: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

.book-apointment-dialog-button-copy {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
}

.book-apointment-dialog-book-button {
    width: 410px;
    height: 55px;
    border-radius: 5px;
    border: none;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

@media (max-width: 480px) {
    .book-apointment-dialog-container {
        width: 95%;
    }

    .book-apointment-dialog-heading {
        font-size: 16px;
    }

    .book-apointment-dialog-copy {
        font-size: 16px;
    }

    .book-apointment-dialog-email {
        font-size: 18px;
    }

    .book-apointment-dialog-button-copy {
        font-size: 16px;
    }

    .book-apointment-dialog-book-button {
        width: 100%;
    }
}