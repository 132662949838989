.drop-area-container-mobile {
    display: none;
}

.drop-area-internal-container-mobile {
    width: 60%;
    height: 90%;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(59, 130, 247, 1);
    text-decoration-line: underline;
}

@media (max-width: 480px) {
    .drop-area-container-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 99%;
        height: 120px;
        border: 1px black dashed;
        border-radius: 10px;
        margin-bottom: 20px;
    }
}